<div [class]="skinName">
    <div>
      @if (type === 'leftSvg') {
        <svg xmlns="http://www.w3.org/2000/svg" width="323.659" height="324.491" viewBox="0 0 323.659 324.491">
          <path fill="none" [attr.stroke]="color" stroke-width="2px" d="M0 0l.225 221.435 249.8 29.962L223.162.226z" opacity="0.2" transform="rotate(-20 246.886 39.781)"/>
        </svg>
      }
      @if (type === 'rightSvg') {
        <svg xmlns="http://www.w3.org/2000/svg" width="364.724" height="362.884" viewBox="0 0 364.724 362.884">
          <path fill="none" [attr.stroke]="color" stroke-width="2px" d="M0 0l.244 251.532L282.6 285.554 252.254.245z" opacity="0.2" transform="rotate(-70.99 188.854 133.328)"/>
        </svg>
      }
    </div>


</div>