<div [class]="skinName">
  <nav class="navbar navbar-default form-navbar" role="navigation">
    <div class="navbar-header form-header">
      @if (settingsService.metroOnly) {
        <a class="navbar-brand">
          <img loading="lazy" [src]="logo" class="hidden-xs">
          <img loading="lazy" [src]="logo" class="visible-xs">
        </a>
      }
      @if (!settingsService.metroOnly) {
        <a class="navbar-brand" (click)="redirectURL()">
          <img loading="lazy" [src]="logo" class="hidden-xs">
          <img loading="lazy" [src]="logo" class="visible-xs">
        </a>
      }
    </div>
    <div class="header-button">
      @if (guest) {
        <a routerLink="/{{routes}}" routerLinkActive="active"> {{ configService.localeKeys[value]}}</a>
      }
      @if (!settingsService.metroOnly) {
        <a routerLink="/{{routes}}" routerLinkActive="active"> {{ configService.localeKeys[value]}}</a>
      }
    </div>
  </nav>

</div>