<div [class]="skinName">
    <div id="card-align2">
        @for(data of cardsData; track data;let i = $index){
        <div class="bg-cards">
            <!-- <div class="card-img-align" (mouseover)="hovered = i" (mouseout)="hovered = -1" [ngStyle]="{'transform':(i == hovered)?alignHoverCard(): 'translateX(0px)'}"> -->
            <div class="card-img-align">
                <div class="card-grad" (click)="redirect(data)">
                    @if(type !== 'genreList' && !contentPartner){
                    <img class="card-img" loading="lazy" [src]="data.poster_landscape? settingsService.getImage(data.poster_landscape, 'card') : 'branding/assets/fallback-movies.svg'">
                    }
                    @if(type !== 'genreList' && contentPartner){
                    <img class="card-img" loading="lazy" [src]="data.poster_landscape? settingsService.getImage(data.poster_landscape, 'card') : 'branding/assets/fallback-movies.svg'">
                    }
                    @if(type === 'genreList'){
                    <img class="card-img" loading="lazy" [src]="settingsService.getImage(data.background_landscape, 'card')">}
                    @if(!contentPartner && settingsService.enableModerators && data.partnerLogo){
                    <span class="partner-logo">
                        <img loading="lazy" [src]="settingsService.getImage(data?.partnerLogo, 'logo')" alt="" />
                    </span>}
                    @if(!continuePath){
                        @if (data?.tvodText && !isSearch && path == 'purchases') {
                            <div class="tvod-info">
                                @if (data?.isRented) {
                                <img loading="lazy" src="/branding/assets/expiry-icon-gold.svg" alt="">
                                } @else {
                                <img loading="lazy" src="/branding/assets/rent-buy-icon.svg" alt="">
                                }
                                <span> {{data?.tvodText}} </span>
                            </div>
                            }
                            @else if(data?.is_tvod){
                                <div class="tvod-info">
                                    <img loading="lazy" src="/branding/assets/rent-buy-icon.svg" alt="">
                                    <span> {{configService?.localeKeys?.['plans.available_to_purchase.tag'] || 'Available to purchase'}} </span>
                                </div>
                            }
                    }
                </div>
                <div (click)="redirect(data)" [ngClass]="type === 'genreList' ? 'genre-img-gradient' : 'img-gradient'">
                </div>
                @if(type === 'genreList'){
                <div (click)="redirect(data)" class="video-detail">
                    <p>{{data.title}}</p>
                    <a> {{configService?.localeKeys?.genre_card_view_content_desc || 'View Content'}} > </a>
                </div>}
                @if(type !== 'genreList'){
                <div class="meta-card">
                    @if(data?.publish_time){
                    <div class="publish-card ">{{data?.publish_time}}</div>
                    }
                    @if(data?.genre && genreCurationEnabled == 1){
                    <span class="pipe">|</span>
                    <span>{{configService?.localeKeys[data?.genre?.split(",")[0]]}}</span>
                    }
                    @if(data?.tags && genreCurationEnabled == 0){
                    <span class="pipe">|</span>
                    <span>{{data?.tags[0]}}</span>
                    }
                    @if(data?.is_series == 1){
                    <span>
                        @if(data?.total_seasons){<span class="pipe">|</span>}
                        @if(data?.total_seasons){<span class="text-style">{{data?.total_seasons}}
                            {{configService.localeKeys?.['common_keys.seasons.text']}}</span>}
                    </span>}
                    @if(data?.is_series == 0){
                    <span class="tags-section d-flex">
                        @if(data?.duration_min){<span class="pipe">|</span>}
                        @if(data?.duration_min){<span class="text-style">{{data?.duration_min | movieDuration}}</span>}
                    </span>
                    }
                </div>}
                @if(type !== 'genreList' && !contentPartner){
                <div class="actions-card">
                    <span class="actions-card-container">
                        @if(!(baseService.guest | async)){
                        <span class="action-card-tooltip">
                            <img (click)="dialog.closeAll();playContentService.playVideo(data,'play')"
                                class="cursor-pointer card-icon" src="branding/assets/card-play.svg">
                            <span
                                class="action-card-tooltip-text">{{configService?.localeKeys?.['player.play_video.btn']
                                || 'Play'}}</span>
                        </span>}
                        @if(!data?.wishlist && !(baseService.guest | async) && !data?.is_livechannel &&
                        !restrictedUser){
                        <span class="action-card-tooltip">
                            <img (click)="addToWishlist(data)" class="cursor-pointer card-icon"
                                src="branding/assets/wishlist_add.svg">
                            <span
                                class="action-card-tooltip-text">{{configService?.localeKeys?.['my_list.pop_menu_list.text']
                                || 'Add to wishlist'}}</span>
                        </span>}
                        @if(data?.wishlist && !(baseService.guest | async) && !data?.is_livechannel && !restrictedUser){
                        <span class="action-card-tooltip">
                            <img (click)="removeFromWishlist(data)" class="cursor-pointer card-icon"
                                src="branding/assets/wishlist_remove.svg">
                            <span class="action-card-tooltip-text">{{configService?.localeKeys?.['my_list.remove.text']
                                || 'Remove from watchlist'}}</span>
                        </span>}
                        <span class="action-card-tooltip">
                            <img (click)="redirect(data)" class="cursor-pointer card-icon"
                                src="branding/assets/content_info.svg">
                            <span class="action-card-tooltip-text">{{configService?.localeKeys?.see_info_title || 'See
                                info'}}</span>
                        </span>
                    </span>
                </div>
                }
            </div>
            @if(type === 'genreList'){
            <div class="title" id="details">
                <div id="head">
                    <div id="title" class="text-hidden">{{data.title}}</div>
                </div>
            </div>
            }
            @if(type !== 'genreList'){
            <div id="details">
                <div id="head">
                    <div id="title" class="text-hidden">{{data.title}}</div>
                    @if(data.duration){
                    <div id="duration">
                        <div id="time">{{data?.duration_min | movieDuration}}</div>
                        <img id="clock" src="branding/assets/duration.svg">
                    </div>
                    }
                </div>
                <div id="footer">
                    <div id="description" class="text-vertical-hidden">
                        {{data.description}}
                    </div>
                </div>
            </div>
            }
        </div>
        }
    </div>
</div>