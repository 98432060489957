export const TERMS = 'terms';
export const PRIVACY = 'privacy';
export const COUPON  = 'coupon';
export const VOUCHER = 'voucher';
export const BULSATCODE = 'bulsatcom';
export const VODAFONE_ROMANIA = 'vodafone-romania';
export const K_GOOGLE_CAPTCHA = '6LeZ5ZcaAAAAABydsaWn1aaylFf27nSwSkFQJSrf';
export const K_QUERY_PARAM_TV = 'tv';
export const K_QUERY_PARAM_APPLE_TV = 'activate-tv';
export const videojsBtnAlign = {
    // controlBar: {
    //   children: [
    //       "playToggle",
    //       "qualityMenu",
    //       "progressControl",
    //       "SubsCapsButton",
    //       "currentTimeDisplay",
    //       "timeDivider",
    //       "durationDisplay",
    //       "fullscreenToggle",
    //   ]
    // }
  };
export const youboraAdapaterCode = {
  "bcoveImaAdsAdapter.adapter,isUsed": "return typeof google !== 'undefined' && this.player.ima3 && this.player.ads",
  "bcoveImaAdsAdapter.adapter,getVersion": "var ret=null;if(typeof google!=='undefined'&&google.ima){ret='bcove-ima '+google.ima.VERSION};return ret",
  "bcoveImaAdsAdapter.adapter,getPlayhead": "var player=this.getVideo().getAdapter().player;if(this.player.ima3){if(this.player.ima3&&this.player.ima3.adPlayer){player=this.player.ima3.adPlayer}};return player.currentTime()",
  "bcoveImaAdsAdapter.adapter,getDuration": "var ret=null;if(this.player.ads&&this.player.ads.ad&&this.player.ads.ad.duration){ret=this.player.ads.ad.duration}else{var player=this.plugin.player;if(this.player.ima3){player=this.player.ima3.adPlayer};ret=(player&&player.duration)?player.duration():null};return ret",
  "bcoveImaAdsAdapter.adapter,getPosition": "var ret=this.getNpawReference().Constants.AdPosition.Midroll;if(this.player.ads&&this.player.ads.ad){switch(this.player.ads.ad.type){case'PREROLL':ret=this.getNpawReference().Constants.AdPosition.Preroll;break;case'MIDROLL':ret=this.getNpawReference().Constants.AdPosition.Midroll;break;case'POSTROLL':ret=this.getNpawReference().Constants.AdPosition.Postroll;break}}else if(this.getVideo().getAdapter().getPlayhead()>=this.getVideo().getAdapter().getDuration()){ret=this.getNpawReference().Constants.AdPosition.Postroll};if(!this.getVideo().getAdapter().flags.isJoined){ret=this.getNpawReference().Constants.AdPosition.Preroll};return ret",
  "bcoveImaAdsAdapter.adapter,getResource": "var ret='unknown';if(this.player.ima3){ret=this.player.ima3.currentAd.getMediaUrl()};return ret",
  "bcoveImaAdsAdapter.adapter,getTitle": "var ret=null;if(this.player.ima3&&this.player.ima3.currentAd){ret=this.player.ima3.currentAd.getTitle()};return ret",
  "bcoveImaAdsAdapter.adapter,getPlayerVersion": "var ret=null;if(this.player.ima3){ret=this.player.ima3.VERSION};return ret",
  "bcoveImaAdsAdapter.adapter,getGivenBreaks": "var ret=null;if(this.player.ima3){ret=this.player.ima3.adsManager.getCuePoints().length};return ret",
  "bcoveImaAdsAdapter.adapter,getBreaksTime": "var ret=null;if(this.player.ima3){var cuelist=this.player.ima3.adsManager.getCuePoints();for(var index in cuelist){if((!cuelist[index]&&cuelist[index]!==0)||cuelist[index]===-1){cuelist[index]=this.getVideo().getAdapter().getDuration()}};ret=cuelist};return ret",
  "bcoveImaAdsAdapter.adapter,getGivenAds": "var ret=null;if(this.player.ima3&&this.player.ima3.currentAd&&this.player.ima3.currentAd.getAdPodInfo()){ret=this.player.ima3.currentAd.getAdPodInfo().getTotalAds()};return ret",
  "bcoveImaAdsAdapter.adapter,getAudioEnabled": "var ret=null;if(this.player.muted()){ret=false}else{ret=!!this.player.volume()};return ret",
  "bcoveImaAdsAdapter.adapter,getIsSkippable": "var ret=false;if(this.player.ima3){ret=this.player.ima3.currentAd&&this.player.ima3.currentAd.isSkippable()};return ret",
  "bcoveImaAdsAdapter.adapter,getIsFullscreen": "var ret=null;if(this.getPlugin().deviceDetector.isIphone()){ret=!this.getPlugin().deviceDetector.isInBackground}else{var container=null;if(this.player.ima3){container=this.player.ima3.el};ret=container?(window.innerHeight<=container.clientHeight+30&&window.innerWidth<=container.clientWidth+30):false};return ret",
  "bcoveImaAdsAdapter.adapter,getIsVisible": "var ret=null;if(this.getPlugin().deviceDetector.isIphone()){ret=!this.getPlugin().deviceDetector.isInBackground}else if(this.player.ima3){ret=this.getNpawUtils().calculateAdViewability(this.player.ima3.el)};return ret",
  "bcoveImaAdsAdapter.adapter,getBitrate": "var bitrate=-1;if(this.player.ima3&&this.player.ima3.currentAd&&this.player.ima3.currentAd.getVastMediaBitrate()){bitrate=this.player.ima3.currentAd.getVastMediaBitrate()};return bitrate",
  "bcoveImaAdsAdapter.adapter,getCreativeId": "var ret=null;if(this.player.ima3&&this.player.ima3.currentAd){ret=this.player.ima3.currentAd.getCreativeAdId()||this.player.ima3.currentAd.getCreativeId()}else if(this.titles){ret=this.titles[(this.getVideo().requestBuilder.lastSent.adNumber||1)-1]._creativeId};return ret",
  "bcoveImaAdsAdapter.adapter,registerListeners": "this.monitorPlayhead(true,false);this.references={'ima3-started':this.startJoinListener.bind(this),'ima3-paused':this.pausedListener.bind(this),'ima3-resumed':this.resumedListener.bind(this),'ima3-complete':this.adEndedListener.bind(this),'ima3-skipped':this.skippedListener.bind(this),'ima3-ad-error':this.errorListener.bind(this),'ads-pod-ended':this.podEndListener.bind(this),'ads-click':this.clickListener.bind(this),'ads-first-quartile':this.firstQuartileListener.bind(this),'ads-midpoint':this.secondQuartileListener.bind(this),'ads-third-quartile':this.thirdQuartileListener.bind(this)};if(this.player){for(var key in this.references){this.player.on(key,this.references[key])}}",
  "bcoveImaAdsAdapter.adapter,startJoinListener,e": "if(this.getVideo().getAdapter()){this.getVideo().getAdapter().firePause()};this.fireStart();this.fireJoin()",
  "bcoveImaAdsAdapter.adapter,pausedListener,e": "this.firePause()",
  "bcoveImaAdsAdapter.adapter,resumedListener,e": "this.fireResume()",
  "bcoveImaAdsAdapter.adapter,skippedListener,e": "this.fireSkip();if(this.getVideo().getAdapter())this.getVideo().getAdapter().fireResume()",
  "bcoveImaAdsAdapter.adapter,adEndedListener,e": "var playheadVal=this.getVideo().requestBuilder.lastSent.adPosition===this.getNpawReference().Constants.AdPosition.Preroll?0:this.getVideo().getAdapter().lastPlayhead;this.fireStop({playhead:playheadVal,adPlayhead:this.getDuration()})",
  "bcoveImaAdsAdapter.adapter,errorListener,e": "if(this.player.ima3){if(e.getError&&e.getError()){var error=e.getError();this.fireError(error.getCode(),error.getMessage())}else{this.fireError()}}",
  "bcoveImaAdsAdapter.adapter,clickListener,e": "if(this.player.ima3){var clickurl=null;if(this.player.ima3.currentAd&&this.player.ima3.currentAd.g){clickurl=this.player.ima3.currentAd.g.clickThroughUrl};this.fireClick(clickurl)}",
  "bcoveImaAdsAdapter.adapter,firstQuartileListener,e": "this.fireQuartile(1)",
  "bcoveImaAdsAdapter.adapter,secondQuartileListener,e": "this.fireQuartile(2)",
  "bcoveImaAdsAdapter.adapter,thirdQuartileListener,e": "this.fireQuartile(3)",
  "bcoveImaAdsAdapter.adapter,podEndListener,e": "this.fireBreakStop()",
  "bcoveImaAdsAdapter.adapter,unregisterListeners": "if(this.monitor)this.monitor.stop();if(this.player&&this.references){for(var key in this.references){this.player.off(key,this.references[key])};delete this.references}",
  "bcoveImaAdsAdapter.adapter,adPlayerErrorListener,e": "var error=e.data.AdError;var noResponseErrors=[1012,1005,301,402,302];var emptyResponse=[1007,1009,303];var wrongResponse=[403,100,503,101,102];if(noResponseErrors.indexOf(error.getErrorCode())>=0){this.fireManifest(youbora.Constants.ManifestError.NO_RESPONSE,error.getMessage())}else if(emptyResponse.indexOf(error.getErrorCode())>=0){this.fireManifest(youbora.Constants.ManifestError.EMPTY,error.getMessage())}else if(wrongResponse.indexOf(error.getErrorCode())>=0){this.fireManifest(youbora.Constants.ManifestError.WRONG,error.getMessage())}else{this.fireError(error.getErrorCode(),error.getMessage())}",

  "genericAdsAdapter.adapter,initProperties": "console.log('init properties');this.isGeneric=true",
  "genericAdsAdapter.adapter,getVersion": "return'7.0.5--videojs-ads'",
  "genericAdsAdapter.adapter,registerListeners": "this.references={adstart:this.adStartListener.bind(this),adend:this.adEndListener.bind(this),adskip:this.adSkipListener.bind(this),adserror:this.errorListener.bind(this),'ads-click':this.clickListener.bind(this)};if(this.player){for(var key in this.references){this.player.on(key,this.references[key])}}",
  "genericAdsAdapter.adapter,unregisterListeners": "if(this.player&&this.references){for(var key in this.references){this.player.off(key,this.references[key])};delete this.references}",
  "genericAdsAdapter.adapter,adStartListener,e": "this.firePlayerLog('adStartListener',{});this.fireStart();this.fireJoin()",
  "genericAdsAdapter.adapter,adEndListener,e": "this.firePlayerLog('adEndListener',{});this.fireStop()",
  "genericAdsAdapter.adapter,adSkipListener,e": "this.firePlayerLog('adSkipListener',{});this.fireSkip()",
  "genericAdsAdapter.adapter,errorListener,e": "this.firePlayerLog('adErrorListener',{});this.fireError()",
  "genericAdsAdapter.adapter,clickListener,e": "this.firePlayerLog('adClickListener',{});this.fireClick()",

  "contribHlsTech,isUsed,adapter":"return!!this.getHls(adapter)",
  "contribHlsTech,getRendition,adapter":"var ret=null;var media=this.getHls(adapter).playlists.media();if(adapter.player.qualityLevels){var qualityLevels=adapter.player.qualityLevels();var selected=qualityLevels[qualityLevels.selectedIndex];if(selected){ret=this.getNpawUtils().buildRenditionString(selected.width,selected.height,selected.bitrate)}}else if(media&&media.attributes){var att=media.attributes;if(att.RESOLUTION){ret=this.getNpawUtils().buildRenditionString(att.RESOLUTION.width,att.RESOLUTION.height,att.BANDWIDTH)}else if(att.BANDWIDTH){ret=this.getNpawUtils().buildRenditionString(att.BANDWIDTH)}else if(att.NAME){ret=att.NAME}};return ret",
  "contribHlsTech,getHls,adapter":"return adapter.getTech().vhs||adapter.getTech().hls||adapter.getTech().hls_||adapter.getTech().hlsplayer",
  "contribHlsTech,getTotalBytes,adapter":"var hls=this.getHls(adapter);var ret=hls.bytesReceived;if(!ret&&hls.stats){ret=hls.stats.mediaBytesTransferred-adapter.acumBytes};return ret",
  "contribHlsTech,getBitrate,adapter":"var ret=null;var media=this.getHls(adapter).playlists.media();if(adapter.player.qualityLevels){var qualityLevels=adapter.player.qualityLevels();var selected=qualityLevels[qualityLevels.selectedIndex];if(selected){ret=selected.bitrate}}else if(media&&media.attributes&&media.attributes.BANDWIDTH){ret=media.attributes.BANDWIDTH}else if(adapter.player.tech_&&adapter.player.tech_.vhs&&adapter.player.tech_.vhs.stats){ret=adapter.player.tech_.vhs.stats.bandwidth}else if(adapter.player.tech_&&adapter.player.tech_.hls&&adapter.player.tech_.hls.bandwidth){ret=adapter.player.tech_.hls.bandwidth}else if(adapter.player.hls&&adapter.player.hls.bandwidth){ret=adapter.player.hls.bandwidth};return ret",

  "dashTech,getResource,adapter":"return this._getDashPlayer(adapter).getSource()",
  "dashTech,_getDashPlayer,adapter":"return adapter.player.dash.mediaPlayer",
  "dashTech,isUsed,adapter":"return adapter.player.dash&&adapter.player.dash.mediaPlayer&&!adapter.player.dash.shakaPlayer",
  "dashTech,getRendition,adapter":"var ret=null;var player=this._getDashPlayer(adapter);if(player){var level=player.getQualityFor('video');if(level){var renditionInfo=player.getBitrateInfoListFor('video')[level];if(renditionInfo){ret=this.getNpawUtils().buildRenditionString(renditionInfo.width,renditionInfo.height,renditionInfo.bitrate)}}};return ret",
  "dashTech,getBitrate,adapter":"var ret=null;var player=this._getDashPlayer(adapter);if(player){var level=player.getQualityFor('video');if(level){ret=player.getBitrateInfoListFor('video')[level].bitrate}};return ret",

  "dashShakaTech,getBitrate,adapter":"return adapter.player.dash.shakaPlayer.getStats().streamBandwidth",
  "dashShakaTech,getResource,adapter":"var ret='';var sp=adapter.player.dash.shakaPlayer;if(typeof sp.getManifestUri==='function'){ret=sp.getManifestUri()}else if(typeof sp.getAssetUri==='function'){ret=sp.getAssetUri()};return ret",
  "dashShakaTech,isUsed,adapter":"return adapter.player.dash&&adapter.player.dash.shakaPlayer",
  "dashShakaTech,getRendition,adapter":"var shaka=adapter.player.dash.shakaPlayer;var stats=shaka.getStats();var ret=this.getNpawUtils().buildRenditionString(stats.width,stats.height,stats.streamBandwidth);var tracks=shaka.getVariantTracks();for(var i in tracks){var track=tracks[i];if(track.active&&track.type==='video'){ret=this.getNpawUtils().buildRenditionString(track.width,track.height,track.bandwidth)}};return ret",

  "hlsJsTech,getTech,adapter":"var tech=adapter.getTech();return tech.vhs||tech.hls||tech.hls_||tech.hlsplayer",
  "hlsJsTech,getRendition,adapter":"var ret=null;var currentLevel=this.getTech(adapter).currentLevel;if(typeof currentLevel!=='undefined'&&currentLevel!==-1&&!!this.getTech(adapter).levels){var level=this.getTech(adapter).levels[currentLevel];if(level){ret=this.getNpawUtils().buildRenditionString(level.width,level.height,level.bitrate)}};return ret",
  "hlsJsTech,isUsed,adapter":"return!!this.getTech(adapter)",
  "hlsJsTech,getHls,adapter":"return this.getTech(adapter)",
  "hlsJsTech,getResource,adapter":"var ret=null;if(this.getTech(adapter)&&this.getTech(adapter).url){ret=this.getTech(adapter).url}else if(this.getHls(adapter)&&this.getHls(adapter).source_){ret=this.getHls(adapter).source_.src};return ret",
  "hlsJsTech,getBitrate,adapter":"var ret=null;var currentLevel=this.getTech(adapter).currentLevel;if(typeof currentLevel!=='undefined'&&currentLevel!==-1&&!!this.getTech(adapter).levels){var level=this.getTech(adapter).levels[currentLevel];if(level&&level.bitrate){ret=level.bitrate}};return ret",

  "shakaTech,getResource,adapter":"var ret = '';var sp = this._getShaka(adapter);if (typeof sp.getManifestUri === 'function'){ret = sp.getManifestUri()} else if (typeof sp.getCurrentSrc === 'function') {ret = sp.getCurrentSrc()} return ret",
  "shakaTech,getBitrate,adapter":"var ret = -1;var sp = this._getShaka(adapter);if (typeof sp.getStats === 'function') {ret = sp.getStats().streamBandwidth} else if ((typeof sp.getVideoTracks === 'function') && (typeof sp.getVideoTrackCurrentIndex === 'function')) {var videoIndex = sp.getVideoTrackCurrentIndex(); var videoTracks = sp.getVideoTracks(); for (var i in videoTracks) { var track = videoTracks[i]; if (track.index == videoIndex) {ret = track.bitrate} } };return ret",
  "shakaTech,isUsed,adapter":"return!!this._getShaka(adapter)",
  "shakaTech,getRendition,adapter":"var shaka=this._getShaka(adapter); var stats=shaka.getStats();var ret=this.getNpawUtils().buildRenditionString(stats.width,stats.height,stats.streamBandwidth);var tracks=shaka.getVariantTracks();for(var i in tracks){var track=tracks[i];if(track.active&&track.type==='video'){ret=this.getNpawUtils().buildRenditionString(track.width,track.height,track.bandwidth);break}};return ret",
  "shakaTech,_getShaka,adapter":"var tech=adapter.getTech(); return tech.shakaPlayer||tech._shakaplayerInstance",

  "adapter,isInAds":"try{return (this.player.ads && this.player.ads.inAdBreak && (typeof this.player.ads.inAdBreak === 'function') && this.player.ads.inAdBreak())}catch(e){return false}",
  "adapter,getBitrate":"var ret=null;var tech=this.getUsedTech();if(tech&&tech.getBitrate){ret=tech.getBitrate(this)};return ret",
  "adapter,checkExistsPlayer":"try{return this.checkExistsObjectOnPage(this.player.el())}catch(err){return true}",
  "adapter,pauseListener,e":"this.firePlayerLog('pauseListener',{});this.firePause({},'pauseListener')",
  "adapter,bufferingListener,e":"this.firePlayerLog('bufferingListener',{});if(!this.flags.isSeeking){this.fireBufferBegin({},false,'bufferingListener')}",
  "adapter,getRendition":"var ret=null;var tech=this.getUsedTech();if(tech&&tech.getRendition){ret=tech.getRendition(this)};return ret",
  "adapter,getPlayerVersion":"var ret=null;if(typeof videojs!=='undefined'){ret=videojs.VERSION};return ret",
  "adapter,playListener,e":"this.firePlayerLog('playListener',{});if(this.isInAds()){return};if(!this.flags.isStarted){this.lastSrc=this.getResource();this._startEvent('playListenerNotStarted')}else{if(this.lastSrc&&this.lastSrc!==this.getResource()){this.fireStop({},'playListenerNewResource');this.acumBytesFunc();this.lastPlayhead=undefined;this._startEvent('playListenerNewResource');this.lastSrc=this.getResource()}}",
  "adapter,loadAdsAdapter": "if(this.getVideo()){if(this.getVideo().getAdsAdapter()===null||this.getVideo().getAdsAdapter().isGeneric){var adapter=null;if(this.getAdapterClass('bcoveImaAdsAdapter').isUsed(this)){adapter=this.getAdapterClass('bcoveImaAdsAdapter');}else if(this.getVideo().getAdsAdapter()===null){adapter=this.getAdapterClass('genericAdsAdapter');adapter.initProperties()};if(adapter){this.getVideo().setAdsAdapter(adapter,this.plugin,true)}}else{if(this.getVideo().getAdsAdapter().registerImaEvents)this.getVideo().getAdsAdapter().registerImaEvents()}}",
  "adapter,errorModifyer,fatal,nonfatal,ignore":"this.fatalList=fatal||[];this.nonFatalList=nonfatal||[];this.ignoreList=ignore||[]",
  "adapter,getPlayrate":"return this.player.playbackRate()",
  "adapter,timeupdateListener,e":"if(this.isInAds()){return};if(this.getPlayhead()>0.1){this.fireJoin({},'timeupdateListener');if(this.joinPolling)this.joinPolling.stop();if(!this.flags.isPaused&&this.lastSeekPlayhead&&this.lastSeekPlayhead!==this.getPlayhead()){this.fireSeekEnd({},'timeupdateListener');this.lastSeekPlayhead=false}};if(!this.flags.isJoined){if(this.lastPlayhead===undefined){this.lastPlayhead=this.getPlayhead()};if(this.lastPlayhead!==this.getPlayhead()){if(this.player.options_&&this.plugin&&!this.getVideo().options['content.id']){this.getVideo().options['content.id']=this.player.options_['data-video-id']};this.fireStart({},'timeupdateListener');this.fireJoin({},'timeupdateListener');if(this.joinPolling)this.joinPolling.stop();this.lastPlayhead=undefined}};if(this.getVideo()._adsAdapter&&!this.getVideo()._adsAdapter.flags.isStarted){this.lastPlayhead=this.getPlayhead()}",
  "adapter,getPlayhead":"var ret=this.player.currentTime();if(this.getVideo()._adsAdapter&&this.getVideo()._adsAdapter.flags.isStarted){ret=this.lastPlayhead||ret};return ret",
  "adapter,getDuration":"var ret=this.player.duration();if(this.player.mediainfo&&typeof this.player.mediainfo.duration!=='undefined'){ret=this.player.mediainfo.duration};return ret",
  "adapter,aderrorListener,e":"this.loadAdsAdapter();if(this.getVideo().getAdsAdapter()&&this.getVideo().getAdsAdapter().adPlayerErrorListener){this.getVideo().getAdsAdapter().adPlayerErrorListener(e)}",
  "adapter,_jointimePolling,e":"if(this.getVideo()){if(!this.getVideo().getAdsAdapter()||!this.getVideo().getAdsAdapter().flags.isStarted){if(this.lastPlayhead!==0&&typeof this.lastPlayhead==='number'&&this.lastPlayhead!==this.getPlayhead()){this.fireJoin({},'jointimePolling');if(this.joinPolling){this.joinPolling.stop()}};this.lastPlayhead=this.getPlayhead()}}else if(this.joinPolling){this.joinPolling.stop()}",
  "adapter,acumBytesFunc":"this.acumBytes+=this.getTotalBytes()||0",
  "adapter,adsEndedListener,e":"this.firePlayerLog('adsEndedListener',{});this.adsEnded=true;if(this.getVideo().requestBuilder.lastSent.adPosition===this.getNpawReference().Constants.AdPosition.Postroll){this.getVideo().fireStop({},'adsEndedListener');this.acumBytesFunc();this.lastPlayhead=undefined}",
  "adapter,getVersion":"return'7.0.5-videojs-js'",
  "adapter,seekedListener,e":"this.firePlayerLog('seekedListener',{});this.fireSeekEnd({},'seekedListener');this.lastSeekPlayhead=this.getPlayhead()",
  "adapter,getTech":"return this.player.tech({IWillNotUseThisInPlugins:true})",
  "adapter,unregisterListeners":"if(this.monitor)this.monitor.stop();if(this.player&&this.references){try{for(var key in this.references){this.player.off(key,this.references[key])}}catch(err){};delete this.references}",
  "adapter,_startEvent,triggerListener":"this.loadAdsAdapter();if(this.player.options_&&this.plugin&&!this.plugin.options['content.id']){this.plugin.options['content.id']=this.player.options_['data-video-id']};this.fireStart({},triggerListener);if(!this.flags.isJoined){if(!this.joinPolling){this.joinPolling=new (this.getNpawReference()).Timer(this._jointimePolling.bind(this),500)};this.joinPolling.start()}",
  "adapter,getIsLive":"var ret=null;if(this.player.mediainfo){ret=true;if(typeof this.player.mediainfo.duration==='number'){ret=(this.player.mediainfo.duration<1||this.player.mediainfo.duration===Infinity)}};if(this.player.duration&&(typeof this.player.duration==='function')){ret=true;var duration=this.player.duration();if(typeof duration==='number'){ret=(duration<1||duration===Infinity)}};if(this.player.isLive&&this.player.isLive==='function'){ret=this.player.isLive()};return ret",
  "adapter,conditionalStop,e":"this.firePlayerLog('conditionalStop',{});if(this.isInAds()){return};if(this.plugin){this.adsEnded=false;this.fireStop({},'conditionalStop');if(this.plugin&&!this.getVideo().options['content.id']){this.getVideo().options['content.id']=null};this.acumBytesFunc();this.lastPlayhead=undefined}",
  "adapter,getTitle":"var ret=null;if(this.player.mediainfo){ret=this.player.mediainfo.name};return ret",
  "adapter,getTotalBytes":"var ret=null;var tech=this.getUsedTech();if(tech&&tech.getTotalBytes){ret=tech.getTotalBytes(this)};return ret",
  "adapter,registerListeners":"this.monitorPlayhead(false,false,1200);this.acumBytes=0;this.ignoreList=[];this.fatalList=[];this.nonFatalList=[];this.references={loadstart:this.loadstartListener.bind(this),adsready:this.loadAdsAdapter.bind(this),play:this.playListener.bind(this),timeupdate:this.timeupdateListener.bind(this),pause:this.pauseListener.bind(this),playing:this.playingListener.bind(this),ended:this.conditionalStop.bind(this),dispose:this.disposeListener.bind(this),seeking:this.seekingListener.bind(this),seeked:this.seekedListener.bind(this),error:this.errorListener.bind(this),adserror:this.aderrorListener.bind(this),adsEnded:this.adsEndedListener.bind(this),texttrackchange:this.textListener.bind(this),waiting:this.bufferingListener.bind(this)};if(this.player){for(var key in this.references){this.player.on(key,this.references[key])}};setTimeout(this.loadAdsAdapter.bind(this),50)",
  "adapter,getResource":"var ret=null;var tech=this.getUsedTech();if(tech&&tech.getResource){ret=tech.getResource(this)};if(!ret){ret=this.player.currentSrc()};return ret",
  "adapter,textListener,e":"if(this.player.textTracks){var tracks=this.player.textTracks();var len=tracks.length;for(var i=0;i<len;++i){if(tracks[i]&&tracks[i].mode==='showing'){this.getVideo().options['content.subtitles']=tracks[i].label||tracks[i].language}}}",
  "adapter,seekingListener,e":"this.firePlayerLog('seekingListener',{});this.fireSeekBegin({},false,'seekingListener')",
  "adapter,disposeListener,e":"this.firePlayerLog('disposeListener',{});if(this.plugin){this.getVideo().fireStop({},'disposeListener');this.getVideo().removeAdsAdapter();this.getVideo().removeAdapter()}",
  "adapter,loadstartListener,e":"this.firePlayerLog('loadstartListener',{});this.loadAdsAdapter()",
  "adapter,getPlayerName":"var name='videojs';if(this.getAdapterClass('contribHlsTech').isUsed(this)){name+='-hls'}else if(this.getAdapterClass('hlsJsTech').isUsed(this)){name+='-hlsjs'}else if(this.getAdapterClass('shakaTech').isUsed(this)){name+='-shaka'}else if(this.getAdapterClass('dashShakaTech').isUsed(this)){name+='-dash-shaka'}else if(this.getAdapterClass('dashTech').isUsed(this)){name+='-dash'};if(this.player.FreeWheelPlugin){name+='-fw'};if(this.player.mediainfo){name+='-bcove'};return name",
  "adapter,playingListener,e":"this.firePlayerLog('playingListener',{});this._startEvent('playingListener');this.fireResume({},'playingListener');if(this.getPlayhead()<1){this.fireSeekEnd({},'playingListener')};this.fireBufferEnd({},'playingListener')",
  "adapter,getUsedTech":"var ret=null;if(this.getPlugin().getAdaptersProperties().usedTech){switch(this.getPlugin().getAdaptersProperties().usedTech.toLowerCase()){case'hls':if(this.getAdapterClass('contribHlsTech').isUsed(this)){ret=this.getAdapterClass('contribHlsTech')};break;case'hlsjs':if(this.getAdapterClass('hlsJsTech').isUsed(this)){ret=this.getAdapterClass('hlsJsTech')};break;case'shaka':if(this.getAdapterClass('shakaTech').isUsed(this)){ret=this.getAdapterClass('shakaTech')};break;case'dashshaka':if(this.getAdapterClass('dashShakaTech').isUsed(this)){ret=this.getAdapterClass('dashShakaTech')};break;case'dash':if(this.getAdapterClass('dashTech').isUsed(this)){ret=this.getAdapterClass('dashTech')};break};if(ret!=null){return ret}};if(this.getAdapterClass('contribHlsTech').isUsed(this)){ret=this.getAdapterClass('contribHlsTech')}else if(this.getAdapterClass('hlsJsTech').isUsed(this)){ret=this.getAdapterClass('hlsJsTech')}else if(this.getAdapterClass('shakaTech').isUsed(this)){ret=this.getAdapterClass('shakaTech')}else if(this.getAdapterClass('dashShakaTech').isUsed(this)){ret=this.getAdapterClass('dashShakaTech')}else if(this.getAdapterClass('dashTech').isUsed(this)){ret=this.getAdapterClass('dashTech')};return ret",
  "adapter,errorListener,e":"this.firePlayerLog('errorListener',{});var ignore=false;var fatal=false;var code=null;var msg=null;if(code!==null){if(this.ignoreList.includes(code)){ignore=true};if(!fatal&&this.fatalList.includes(code)){fatal=true}else if(fatal&&this.nonFatalList.includes(code)){fatal=false}};if(!ignore){if(fatal){this.fireFatalError(code,msg,undefined,undefined,'errorListener');this.acumBytesFunc();this.lastPlayhead=undefined;this.crashed=true}else{this.fireError(code,msg,undefined,undefined,'errorListener')}}"
}