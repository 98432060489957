import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { bulsatOpertor, bulsatSubcription, voucherCode } from 'src/app/shared/constants';
import { environment } from 'src/environment';
import { BaseService, SettingsService } from '.';
import { OperatorService } from './operator.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {
  operators;
constructor(private http: HttpClient, private storageService: StorageService, private settingsService: SettingsService,
  private baseService: BaseService) { }
// this service calling for voucher code details
  validateVoucher(data): Observable<any> {
    const region: string = this.storageService.getLocalStore('region');
    const locale: string = this.storageService.getLocalStore('locale');
    const uId: string = this.storageService.getLocalStore('u_id');
    const gId: string = this.storageService.getLocalStore('g_id');
    let params = new HttpParams()
      .set('region', region)
      .set('locale', locale)
      .set('code', data.code);
    if (uId) {
      params = params.append('u_id', uId);
    } else {
      params = params.append('g_id', gId);
    }
    let postData = data;
    if (uId) {
      postData['u_id'] = uId;
    } else {
      postData['g_id'] = gId;
    }
    if(this.baseService.telcoSlug)
     data['operator'] = this.baseService.telcoSlug;
    const editUrl = environment.apiEndpoint + environment.version + voucherCode;
    Object.assign(postData, this.settingsService.handleDataMartAnalyticsParamsData());
    return this.http.post<any>(editUrl, postData, { params }).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response;
        }
      })
    );
  }
    // this service call for bulsatoperater details
    validateBulsat(data): Observable<any> {
      let params = new HttpParams()
      let postData = data;
      if(this.baseService.telcoSlug)
       data['operator'] = this.baseService.telcoSlug;
      const bulsatUrl = environment.apiEndpoint + environment.v3_version + bulsatOpertor;
      Object.assign(postData, this.settingsService.handleDataMartAnalyticsParamsData());
      return this.http.post<any>(bulsatUrl, postData, { params }).pipe(
        map((response: any) => {
          if (response.status_code === 200) {
            this.storageService.setLocalStore("customerIdentifierType",response.data.customer_identifier_type);
            this.storageService.setLocalStore("operatorReference",response.data.operator_reference_number);
            return response;
          }
        })
      );
    }
  // this service call subcription for bulsatopertor User
  bulsatSubcription(data){
    let params = new HttpParams()
    .set('code', data.code);
    let postData = data
    if(this.baseService.telcoSlug) data['operator'] = this.baseService.telcoSlug;
    else if(this.baseService.verifyEmailTelcoSlug) data['operator'] = this.baseService.verifyEmailTelcoSlug;
    const bulsatSubUrl = environment.apiEndpoint + environment.v3_version + bulsatSubcription;
    Object.assign(postData, this.settingsService.handleDataMartAnalyticsParamsData());
    return new Promise((resolve, error) => {
      this.http.post<any>(bulsatSubUrl, postData, { params }).subscribe((resp) => {
        resolve(resp);
      }, (err) => {
        resolve(err);
      })
    })
  }
  }