import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { completeTvod, createTvod, payStripeCompleteIdealPaymentMethod, stripeCompleteIdealPaymentMethod, stripeCompleteIdealSubscription } from 'src/app/shared/constants';
import { environment } from 'src/environment';
import { BaseService } from './base.service';
import { ConfigurationService } from './configuration.service';
import { DatalayerService } from './datalayer.service';
import { StorageService } from './storage.service';
import { SubscriptionService } from './subscription.service';
import { UikitService } from './uikit.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  proceedToPay = false;
  stripe: import("@stripe/stripe-js").Stripe;;
  idealBank: import("@stripe/stripe-js").StripeIdealBankElement;
  paymentMethodLength = 1;
  public backNavigation = new BehaviorSubject(false);

  constructor(private subscriptionService: SubscriptionService, private uikitService: UikitService,
              private baseService: BaseService, private http: HttpClient,
              private datalayerService: DatalayerService, private storageService: StorageService,
              private configService: ConfigurationService,public dialog: MatDialog) {}

  idealPayItem(createSubsObj,redirectPage) {
    this.subscriptionService.createIdealSubcription(createSubsObj).subscribe(resp => {
      const intentData = resp.intent;
      const clientSecretKey = intentData.client_secret;
      const intentType = intentData.intent_type;
      if (resp.status) {
        this.confirmIdealSetup(clientSecretKey, intentType,createSubsObj, redirectPage);
      } else {
        const errMsg = resp.error?.message;
        this.uikitService.staticErrorMessage(errMsg);
        this.baseService.loaderSource.next(false);
      }
    });
  }

  async confirmIdealSetup(clientSecretKey = '', intentType = '',createSubsObj ,redirectPage) {
    let returnURL = `${environment.routeUrl + createSubsObj.region  +  redirectPage}?stripe_type=ideal&intent_type=${intentType}&session_id=${createSubsObj.session_id}`;
    const coupon = this.storageService.getLocalStore('discount_code');
    if (coupon) returnURL = returnURL + `&coupon_code=${coupon}`;
    const paymentData = {
      payment_method: {
        ideal: this.idealBank,
        billing_details: {
          name: createSubsObj.name,
          email: createSubsObj.email,
        }
      },
      return_url: returnURL
    };
    try {
      if(intentType === 'payment') {
        await this.stripe.confirmIdealPayment(clientSecretKey, paymentData);
        this.baseService.loaderSource.next(false);
      } else {
        await this.stripe.confirmIdealSetup(clientSecretKey, paymentData);
        this.baseService.loaderSource.next(false);
      }
    } catch (error) {
      this.baseService.loaderSource.next(false);
    }
  }

  completeIdealPayment(data): Observable<any> {
    const completeIdealPaymentURL = environment.apiEndpoint + environment.version + stripeCompleteIdealSubscription;
    return this.http.post(completeIdealPaymentURL, data).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          this.subscriptionService.userType = 1;
          return response;
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
      })
    );
  }

  paymentCheck(transactionDetails) {
    if (transactionDetails.payment_status === 'PAID') {
      let userType = 'Free User';
      let operator_name = '';
      if (transactionDetails.user_type == '1') {
        this.storageService.setLocalStore('event_user_status', transactionDetails.user_type);
      }
      if (transactionDetails.user_telco) {
        userType = 'Operator User';
        operator_name = transactionDetails.user_telco;
      } else if (transactionDetails.user_type === '1') {
        userType = 'Paid User';
      }
      this.storageService.setLocalStore('user_type', userType);
      this.storageService.setLocalStore('operator_name', operator_name);
      const datalayerData = {
        event: 'GAEvent',
        eventCategory: 'Payment',
        eventAction: 'Success',
        eventLabel:
          (transactionDetails.plan ? transactionDetails.plan : transactionDetails.content_title) + ' - ' + transactionDetails.amount,
        loginStatus: (this.storageService.getLocalStore('u_id') ? 'True' : 'False'),
        userId: (this.storageService.getLocalStore('u_id') ? this.storageService.getLocalStore('u_id') : ''),
        notificationStatus:  localStorage.getItem('emailNotify') ||  'False',
        region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
        platform: this.baseService.browserType,
        userType: this.storageService.getLocalStore('user_type'),
        operator: this.storageService.getLocalStore('operator_name')
      };
      const datalayerPurchageData = {
        event: 'purchase',
        eventCategory: 'Payment',
        eventAction: 'Success',
        eventLabel: (transactionDetails.plan ? transactionDetails.plan : transactionDetails.content_title) + ' - ' + transactionDetails.original_amount, //User’s selected plan and its price should be sent dynamically 
        eventValue: transactionDetails.original_amount, //Price value is should be sent with “.” delimiter.
        nonInteraction: 'true',
        ecommerce: {
          currencyCode: transactionDetails.currency_code,
          purchase: {
            actionField: {
              id: transactionDetails.transaction_reference_no, //transaction id
              revenue: transactionDetails.original_amount, //Paid amount with dot decimal
              coupon: transactionDetails.coupon_code //coupon code. If a user uses a coupon code, it should be sent here
            },
            products: [{
              name: (transactionDetails.subscription_plan ? transactionDetails.subscription_plan : transactionDetails.content_title), //Purchased plan’s name
              id: transactionDetails.purchage_plan_id, // Purchased plan’s id 
              price: transactionDetails.original_amount// Purchased plan’s price
            }
            ]
          }
        }
      };
      this.datalayerService.addDatalayer(datalayerData);
      this.datalayerService.addDatalayer(datalayerPurchageData);
    } else {
      localStorage.removeItem('event_user_status');
      const datalayerData = {
        event: 'GAEvent',
        eventCategory: 'Payment',
        eventAction: 'Error',
        eventLabel:
          (transactionDetails.plan ? transactionDetails.plan : transactionDetails.content_title) + ' - ' + transactionDetails.amount,
        loginStatus: (this.storageService.getLocalStore('u_id') ? 'True' : 'False'),
        userId: (this.storageService.getLocalStore('u_id') ? this.storageService.getLocalStore('u_id') : ''),
        notificationStatus: localStorage.getItem('emailNotify') ||  'False',
        region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
        platform: this.baseService.browserType,
        userType: this.storageService.getLocalStore('user_type'),
        operator: this.storageService.getLocalStore('operator_name')
      };
      this.datalayerService.addDatalayer(datalayerData);
    }
  }

  showAlertBox(){
    const title = this.configService.localeKeys?.["plans.info_processed_do_not_press_back_button.text"] || "Your payment is in progress, please don't press the back button now as it can lead to payment failure.";
    const btnText = this.configService.localeKeys?.["plans.dismiss.text"];
    if (this.dialog.openDialogs.length === 0) {
      this.dialog.open(AlertBoxComponent,{
        data: {title,btnText},
        hasBackdrop: false,
        position: {top: '0px'}
      })
    } else {
      this.dialog.closeAll();
    }
  }

  completeIdealUpdation(queryParams): Observable<any> {
    const locale = this.storageService.getLocalStore('locale');
    const region = this.storageService.getLocalStore('region');
    const completeIdealPaymentURL = environment.apiEndpoint + environment.version + stripeCompleteIdealPaymentMethod;
    let subsIdealData = { locale, region};
    subsIdealData = { ...subsIdealData, ...queryParams};
    delete subsIdealData['stripe_type'];
    const subsIdealPostData = subsIdealData;
    return this.http.post(completeIdealPaymentURL, subsIdealPostData);
  }

  // tvod
  createTvodPayment(reqObj) {
    const tvodPaymentUrl = environment.apiEndpoint + environment.version + createTvod;
    return this.http.post(tvodPaymentUrl, reqObj).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response;
        }
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  completeTvodPayment(reqObj) {
    const tvodCompleteUrl = environment.apiEndpoint + environment.version + completeTvod;
    return this.http.post(tvodCompleteUrl, reqObj).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response;
        }
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

}
