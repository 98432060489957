import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  appLanguage: Array<object>;

  constructor() { }

  languageFn(configLang: Array<object>){
    this.appLanguage = configLang;
  }
}
