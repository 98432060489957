import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  metaTitle: any;
  keywords: any;
  description: any;
  seoEnabled
  public pageH1Tag = new BehaviorSubject(false);

  constructor(private titleService: Title, private metaService: Meta) { }

  setMetatitle(metaTitle): void {
    if(this.seoEnabled) this.titleService.setTitle(metaTitle);
  }

  setMetaTags(metaData): void {
    if(this.seoEnabled) this.metaService.addTags(metaData);
  }

  updateMetaTags(metaData): void {
    if(this.seoEnabled) this.metaService.updateTag({ name: metaData.name , content: metaData.content });
  }

  defaultMetaTag(): void {
    if (this.metaTitle) {
      this.titleService.setTitle(this.metaTitle);
    }
    if (this.keywords) {
      this.metaService.updateTag({ name: 'keywords', content: this.keywords });
    }
    if (this.description) {
      this.metaService.updateTag({ name: 'description', content: this.description });
    }
  }

}
