<div [class]="skinName">
  <div class="search-bg-img">
    <div class="search-page" [ngClass]="{'h-100': (searchCount==0 && this.totalGetCount == 0)}">
      <!-- <a class="pull-right close" (click)="closeSearch()">
    </a> -->
    <!-- <div class="search-box">
    <span class="glyphicon glyphicon-search pull-left search-logo"></span>
    <input id="searchTerm" [(ngModel)]="searchKey" placeholder="{{ configService.localeKeys?.['search.start_typing.text']}}" type="search" (ngModelChange)="getSearchData(searchKey)" (keyup.enter)="goToSearchPage(searchKey)" spellcheck="false" autocomplete="off"
      [ngModelOptions]="{debounce: 1000}">
    <a class="pull-right close" (click)="closeSearch()">
    </a>
  </div> -->
  <div class="search-count" [ngClass]="(searchCount==0 && this.totalGetCount == 0) ? 'height-100':'load-height'">
    @if (!overrideLoader) {
      <div [ngClass]="(searchCount==0 && this.totalGetCount == 0) ? 'no-result-content' : 'result-block triangle'">
        @if (totalGetCount) {
          <div>
            <!-- {{ configService.localeKeys?.['search.found.text'] }} {{searchCount}} {{ configService.localeKeys?.['search.results_for.text']}} <span>&#8220;</span>{{searchName}}<span>&#8221; {{ configService.localeKeys?.['search.results_indicator_part3.text']}} </span>. -->
            {{ configService.localeKeys?.['search.results_for.text']}} <span>&#8220;</span>{{searchName}}<span>&#8221; {{ configService.localeKeys?.['search.results_indicator_part3.text']}} </span>.
          </div>
        }
        @if ((searchCount==0 && this.totalGetCount == 0)) {
          <div>
            <div id="no-result">{{configService.localeKeys?.['error.no_results.text']}} <span>&#8220;</span>
            {{searchName}}<span>&#8221;</span>.</div>
            <!-- <div class="no-result-else">{{ configService.localeKeys?.['search.may_be_something_else.text'] }}</div> -->
          </div>
        }
      </div>
    }
    @if (overrideLoader) {
      <div id="align-lottie">
        <ng-lottie width="100px" height="100px" [options]="options"></ng-lottie>
      </div>
    }
  </div>
  @if (totalGetCount) {
    <div class="searchList">
      <app-card-list id="card-align" [searchTerm]="searchName" [purchases]="purchases" [isSearch]="true" [numCardsInRow]="numCardsInRow" [cards]="searchData" [searchComp]="true">
      </app-card-list>
      @if (paginate) {
        <div class="loader">
          <img src="branding/assets/card-loader.svg" alt="">
        </div>
      }
      <div class="card-clearfix"></div>
      <div class="open-more">
        @if (showMoreResult && !showLessButton) {
          <div id="open-trans">
            <span> {{configService.localeKeys?.['common_keys.more.btn']}} <img (click)="openMore()" class="cursor-pointer" src="branding/assets/show-more.svg" alt=""></span>
          </div>
        }
        <!-- <div *ngIf="showLessButton" style="transition: all 0.9s ease-in-out;">
        <span> {{configService.localeKeys?.['common_keys.more.btn']}} <img (click)="showLess()" style="transform: rotate(180deg);cursor: pointer;" src="branding/assets/show-more.svg" alt=""></span>
      </div> -->
    </div>
  </div>
}
<!-- <div *ngIf="searchCount" class="something-else">
{{ configService.localeKeys?.['search.may_be_something_else.text'] }}
</div> -->
<!-- <div *ngFor="let section of data" class="search-data">
<div *ngIf="section.list.length>0">
  <div class="box-title">
    <div class="box-head row no-margin">
      <h3 class="main-title pull-left">{{section.title}}</h3>
    </div>
  </div>
  <div class="section-line"></div>
  <app-slick-item [data]="section" (closeEvent)="closeSearch()" [searchData]="searchKey"></app-slick-item>
</div>
</div> -->
<!-- <div class="row no-margin" style="margin-top:40px !important;color:var(--mm-font-color)" *ngIf="search_count==0 && searchKey.length >=3">
{{no_results_message }}
</div> -->

@if (searchOpenedFirst) {
  <div class="search-description">
    <img loading="lazy" class="search-zoom" src="branding/assets/search-zoom.svg">
    <p>{{ configService.localeKeys?.['search.searching_guide.text']}}</p>
  </div>
}
</div>
</div>

</div>