import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services';
import { ConfigurationService } from '../services/configuration.service';
import { UikitService } from '../services/uikit.service';

@Injectable({
  providedIn: 'root'
})
export class OperatorPaymentGuard  {
  constructor(private storageService: StorageService, private router: Router,
    private uikitService: UikitService, private configService: ConfigurationService){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const ssoOrFtp = this.storageService.getLocalStore("sso_or_ftp");
    if(ssoOrFtp){
      this.uikitService.staticErrorMessage(this.configService.localeKeys?.["operator.voucher_code_not_supported_popup.error"] || 'Vouchers are not supported for your account as you are signed up via one of our partner channels(Telcos & other providers)')
      this.router.navigateByUrl('/home');
      return false;
    } else {
      return true;
    }
  }

}