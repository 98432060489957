import { environment } from 'src/environment';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseService } from 'src/app/core/services/base.service';
import { SettingsService } from 'src/app/core/services';

@Component({
  selector: 'app-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss'],
  standalone: true,
  imports: []
})
export class AlertBoxComponent implements OnInit {

  skinName = environment.skinName;
  constructor(public dialogRef: MatDialogRef<AlertBoxComponent>, @Inject(MAT_DIALOG_DATA) public dialogData,
    private baseService: BaseService, private router: Router, public settingsService: SettingsService) { }

  ngOnInit(): void {
    this.baseService.paymentProcessing.subscribe((value) => {
      if (!value) {
        this.dialogRef.close();
      }
    });
  }

}
