import { Injectable } from '@angular/core';
import SecureLS from 'secure-ls';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  ls = new SecureLS({ isCompression: false });

  constructor() { }

  setLocalStore(name: string, data): void {
    this.ls.set(name, data);
  }

  getLocalStore(name: string) {
    try {
      return this.ls.get(name);
    }
    catch (e) {
      this.ls.clear();
      window.location.reload();
      // return localStorage.getItem(name)
    }
  }

  removeLocalStore(name: string): void {
    this.ls.remove(name);
  }

  removeAll(): void {
    this.ls.removeAll();
  }

  setSessionStore(name: string, data) {
    sessionStorage.setItem(name, JSON.stringify(data));
  }

  getSessionStore(name: string) {
    return JSON.parse(sessionStorage.getItem(name));
  }
}
