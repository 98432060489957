import { Injectable } from '@angular/core';
declare var UIkit: any;
@Injectable({
  providedIn: 'root'
})
export class UikitService {

  notificationCSS = 'text-align:center;color:white;border-radius:3px;margin-top: 1.5em;'
  notifyPadding = '10px 20px 10px 10px';
  errorNotifyColor = { film1: '#fa4b14', filmbox: 'red', dizi: 'red'};
  errorList = [
    { statusCode: 401, errorCode: [1021, 1034, 1035, 1036] },
    { statusCode: 403, errorCode: [1135] },
    { statusCode: 404, errorCode: [4003, 4017, 4011, 4014] },
    {
      statusCode: 422,
      errorCode: [1011, 1021, 1023, 1031, 1041, 1058, 1075, 1080, 1090, 1111, 1116, 1121, 1133, 1136, 1139, 1151, 1155, 1161,
        1165, 1171, 1175, 1118, 1201, 1211, 1221, 1231, 1235, 1251, 1261, 1271, 1321, 1325, 1331, 1335, 2001, 2006]
    },
    {
      statusCode: 500,
      errorCode: [1012, 1018, 1019, 1021, 1032, 1042, 1059, 1076, 1081, 1091, 1095, 1112, 1113, 1117, 1118, 1122, 1123, 1134, 1137,
        1140, 1152, 1156, 1162, 1166, 1172, 1176, 1202, 1212, 1222, 1232, 1236, 1252, 1262, 1272, 1322, 1326, 1332, 1336,
        2002, 2003, 2007, 2008]
    },
  ];
  timeout = 3000;
  constructor() { }

  notifyError(err) {
    if (err && err.error && err.error.error && err.error.error.message) {
      const errorMessage = err.error.error.message;
      UIkit.notification(`<div style="background:#DB2B20;${this.notificationCSS}">
      <div style="padding:${this.notifyPadding}">${errorMessage}</div></div>`, {
        pos: 'top-center',
        // timeout: this.timeout
      });
    }
  }

  notifySuccess(res) {
    const statusCode = res.status_code;
    const successMessage = res.message;
    if (statusCode === 200) {
      UIkit.notification(`<div style="background:green;${this.notificationCSS}">
      <div style="padding:${this.notifyPadding}">${successMessage}</div></div>`, {
        pos: 'top-center',
        timeout: this.timeout
      });
    }
  }
  staticErrorMessage(message) {
    UIkit.notification(`<div style="background:#DB2B20;${this.notificationCSS}">
      <div style="padding:${this.notifyPadding}">${message}</div></div>`, {
        pos: 'top-center',
        // timeout: this.timeout
    });
  }

  staticSuccessMessage(message) {
    UIkit.notification(`<div style="background:green;${this.notificationCSS}">
    <div style="padding:${this.notifyPadding}">${message}</div></div>`, {
      pos: 'top-center',
      timeout: this.timeout
    });
  }

}
