import { environment } from 'src/environment';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges,OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { BaseService, SettingsService, StorageService } from 'src/app/core/services';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import { ContentService } from 'src/app/core/services/content.service';
import { DatalayerService } from 'src/app/core/services/datalayer.service';
import { HomeService } from 'src/app/core/services/home.service';
import { PlayContentService } from 'src/app/core/services/play-content.service';
import * as $ from 'jquery';
import { WishlistService } from 'src/app/core/services/wishlist.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { MovieDurationPipe } from '../../pipes/movie-duration.pipe';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss', './card-list.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, NgClass, AsyncPipe, MovieDurationPipe]
})
export class CardListComponent implements OnInit, OnChanges, AfterViewInit {


  @Input() cards;
  @Input() numCardsInRow: number;
  @Input() contentStatus;
  hovered: number = -1;
  @Input() searchComp;
  @Input() type;
  @Input() isSearch;
  @Input() searchTerm;
  @Input() allData;
  @Input() purchases;
  @Input() path;
  breadCrumb: any;
  genreCurationEnabled;
  epgEnabled;
  restrictedUser;
  contentPartner = false;
  imagePath;
  cardsData: any;
  continuePath = false;

  skinName = environment.skinName;
  constructor(public settingsService: SettingsService, public baseService: BaseService,
    public playContentService: PlayContentService, private contentService: ContentService,
    private storageService: StorageService, private datalayerService: DatalayerService, public dialog: MatDialog,
    private router: Router, private homeService: HomeService, public configService: ConfigurationService,
    private wishlistService: WishlistService, private changeDetector: ChangeDetectorRef, private route: ActivatedRoute, private applicationService: ApplicationService) {
    this.imagePath = this.settingsService.imagePath;
    this.genreCurationEnabled = this.settingsService.genreCurationEnabled;
    this.restrictedUser = environment.isEzProxy || (this.storageService.getLocalStore('hoopla_user') == '1') ? true : false;
  }

  ngOnChanges(changes: SimpleChanges) {
    const newCards = changes.cards.currentValue;
    if (!this.cardsData) {
      this.cardsData = [...newCards];
      if(!this.isSearch && this.path && this.path == 'purchases') this.updateDataWithTvod();
    } else {
      const uniqueNewCards = newCards.filter(newCard => !this.cardsData.some(existingCard => existingCard.contentId === newCard.contentId));
      if(!this.isSearch && this.path && this.path == 'purchases'){
        uniqueNewCards.forEach(newCard => {
          this.updateDataWithTvodSecond(newCard);
          this.cardsData.push(newCard);
        });
      } else this.cardsData = [...this.cardsData,...uniqueNewCards];
    }
    this.setNumCards();
  }

  async ngOnInit() {
    const urlSegments = this.route.snapshot.url.map(segment => segment.path);
    if(urlSegments[1] == 'continue') this.continuePath = true;
    else this.continuePath = false;
    this.contentPartner = urlSegments.includes('content_partner');
    this.epgEnabled = this.settingsService.epgEnabled;
    this.breadCrumb = {
      menu_item: this.route.snapshot.queryParams.menu_item,
      slug: this.route.snapshot.queryParams.slug
    };
    if (this.allData && this.allData.group) {
      this.breadCrumb.submenu_title = this.allData.group.title_translation_key;
    }

    // wishlist
    await this.homeService.contentWishlistData.subscribe(async(dataWishlist) => {
      if (this.contentStatus) {
        this.cardsData = dataWishlist;
      }
      await this.getWishlistData();
    });
  }

  getWishlistData() {
    this.homeService.contentWishlist.subscribe((data) => {
      if (this.cardsData?.length && data?.length) {
        this.cardsData.forEach(banner => {
          if (banner.is_series) {
            banner.wishlist = data.includes(banner.series_slug);
          } else {
            banner.wishlist = data.includes(banner.content_slug);
          }
        });
      } else {
        this.cardsData.forEach(banner => {
          banner.wishlist = false;
        });
      }
      this.baseService.loaderSource.next(false);
    });
  }

  async updateDataWithTvod() {
    if (this.cardsData?.length) {
      for (let i = 0; i < this.cardsData.length; i++) {
        const item = this.cardsData[i];
        if (item?.is_tvod) {
          item.tvodText = this.configService.localeKeys?.['plans.available_to_purchase.tag'] || 'Available to purchase';
          const tvodObj = await this.contentService.getTvodInfo(item);
          const mergedObj = Object.assign({}, item, tvodObj);
          this.cardsData[i] = mergedObj;
        } else continue;
      }
    }
  }

  async updateDataWithTvodSecond(card: any) {
    if (card && card.is_tvod) {
      card.tvodText = this.configService.localeKeys?.['plans.available_to_purchase.tag'] || 'Available to purchase';
      const tvodObj = await this.contentService.getTvodInfo(card);
      Object.assign(card, tvodObj);
    }
  }

  alignHoverCard() {
    const modValue = (this.hovered % this.numCardsInRow);
    if (this.hovered > -1) {
      switch (modValue) {
        case 0:
          return 'translateX(79px)';
        case (this.numCardsInRow - 1):
          return 'translateX(-79px)';
        default:
          return 'translateX(0px)';
      }
    }
  }

  setNumCards() {
    // const cardAlignDiv = document.getElementById('card-align');
    // if (cardAlignDiv) {
    //   cardAlignDiv.setAttribute('style', `grid-template-columns:repeat(${this.numCardsInRow}, ${1}fr)`);
    // }
    const numCards = this.numCardsInRow;
    const cardAlignDiv2 = document.getElementById('card-align2');
    if (cardAlignDiv2) {
      cardAlignDiv2.setAttribute('style', `grid-template-columns:repeat(${numCards}, ${1}fr);`);
    }
    if (this.settingsService.enableModerators) {
      this.cardsData = this.cardsData.map(item => {
        let matchingId = this.applicationService.contentPartners.find(partner => partner.id == item.content_partner_id);
        return { ...item, ...{ partnerLogo: matchingId?.partner_logo || '' } };
      })
    }
  }

  ngAfterViewInit() {
    this.setNumCards();
  }

  addGTMData(video) {
    const datalayerData = {
      event: 'GAEvent',
      eventCategory: 'Content Click',
      eventAction: (video.collection_title) ? video.collection_title : video.seasons_title,
      eventLabel: video.title,
      loginStatus: (this.storageService.getLocalStore('u_id') ? 'True' : 'False'),
      userId: (this.storageService.getLocalStore('u_id') ? this.storageService.getLocalStore('u_id') : ''),
      content: this.datalayerService.getCategoryDatalayer(video, 'content'),
      contentId: video.contentId,
      VideoCategory: this.datalayerService.getCategoryDatalayer(video, 'category'),
      notificationStatus: localStorage.getItem('emailNotify') || 'False',
      region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
      platform: this.baseService.browserType,
      userType: this.storageService.getLocalStore('user_type'),
      operator: this.storageService.getLocalStore('operator_name')
    };
    this.datalayerService.addDatalayer(datalayerData);
  }

  addToWishlist(data) {
    this.playContentService.addToWishlist(data);
  }
  removeFromWishlist(data) {
    this.wishlistService.removeFlagOn = false;
    this.homeService.removeListFlage = true;
    this.playContentService.removeList = true;
    this.playContentService.removeWishlist(data);
    let response = {
      title: 'wishlist'
    }
    if (this.wishlistService.seekCount > this.settingsService.takeValue) {
      this.homeService.userContent(response).subscribe(data => {
      });
    }
  }

  redirect(video) {
    if (this.dialog.openDialogs.length > 0) {
      const clearInput = <HTMLInputElement>document.querySelector(".search-field");
      clearInput.value = '';
      const showSearch = <HTMLInputElement>document.getElementById("searchIcon");
      const showCancel = <HTMLInputElement>document.getElementById("cancelIcon");
      showCancel.style.opacity = '0';
      showSearch.style.opacity = '1';
      showCancel.style.zIndex = '0';
      showSearch.style.zIndex = '1';
      const inputBox = <HTMLInputElement>document.getElementById("inputSearch");
      if (window.innerWidth > 767 && window.innerWidth < 1001) {
        inputBox.setAttribute('style', 'width: 23px ');
        document.getElementById('listCategory').style.display = 'block';
        $('#inputSearch').blur();
      } else {
        inputBox.setAttribute('style', 'width: 280px ')
      }
      this.dialog.closeAll();
    }
    this.addGTMData(video);
    if (this.contentPartner) {
      this.partnerRouting(video);
    } else {
      if (this.type === 'genreList') {
        const navigationExtras: NavigationExtras = {
          queryParams: {
            menu_item: this.route.snapshot.queryParams.menu_item,
            submenu_title: this.allData.group.title_translation_key,
            slug: this.allData.group.slug
          }
        };
        this.router.navigate(['/collection', video.slug], navigationExtras);
      } else if (video.is_livechannel) {
        if (this.epgEnabled) this.baseService.storeEPGValue(video.slug);
        else this.router.navigate(['live', video?.slug]);
      } else if (video.is_series) {
        const seriesSlug = video?.seasons_slug || video?.series_slug;
        const contentSlug = video?.content_slug;
        if (video.watched_duration) {
          this.contentService.contentSlug = contentSlug;
          this.contentService.seriesSlug = seriesSlug;
          this.contentService.seekValue = video.watched_duration;
        } else {
          this.contentService.seekValue = 0;
        }
        this.router.navigate(['/series', seriesSlug, contentSlug]);
      } else {
        this.router.navigate(['/video', video.collection_slug, video.content_slug]);
      }
    }
  }

  partnerRouting(value){
    this.router.navigate(['/content-partner', value.slug]);
  }

}
