import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { K_QUERY_PARAM_TV, SOCIAL_APP_TV_FLAG, SOCIAL_SMART_APP_TV_FLAG, token, K_QUERY_PARAM_APPLE_TV } from 'src/app/shared/constants';
import { StorageService } from './storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { DeepLinkService } from './deep-link.service';
import { UikitService } from './uikit.service';
import { SettingsService } from './settings.service';
import { environment } from 'src/environment';
import { CategoriesService } from './categories.service';
import { HomeService } from './home.service';
import { L_CATEGORY_META_DATA } from 'src/app/shared/constants';
import { liveChannel } from 'src/app/shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { SeoService } from './seo.service';

declare function videojs(player: any, options?:any): any;
@Injectable({
  providedIn: 'root'
})
export class BaseService {

  loginMode: number;
  public guestSource = new BehaviorSubject(true);
  guest = this.guestSource.asObservable();
  public loaderSource = new BehaviorSubject(false);
  public signLoaderSource = new BehaviorSubject(false);
  public paymentUpdate = new BehaviorSubject(true);
  public voucherValidation = new BehaviorSubject(false);
  public showCardData = new BehaviorSubject({ no_past_due: true, session_id: undefined });
  public signinCaptchaValidation = new BehaviorSubject(0);
  public voucherCaptchaValidation = new BehaviorSubject(0);
  public headerCategoryId = new BehaviorSubject('');
  public searchData = new BehaviorSubject('');
  public paymentProcessing = new BehaviorSubject(false);
  public disableBuuton = new BehaviorSubject(false);
  authPayErrorRoute = '/auth/stripe/payment/error';
  voucherPayErrorRoute = '/voucher/payment/error';
  setTimeoutQuality: number = 1000;
  setTimeoutAudio: number = 20;
  allPages;
  baseURL;
  isLive;
  callingProgramList: boolean;
  startState: string;
  showIosApp: boolean;
  showAndroidApp: boolean;
  initLocationHref: string;
  keyQueryParamTV = K_QUERY_PARAM_TV;
  keyQueryParamAppleTV = K_QUERY_PARAM_APPLE_TV;
  disableFormButton = false;
  params:any;
  channelsData;
  takeValue: number;
  livePlayerId: any;
  userAgentString;
  headerCloseEvent = new BehaviorSubject<Boolean>(false);
  previousSlugValue: any;
  browserType;
  selectPlan;
  telcoSlug = '';
  verifyEmailTelcoSlug ='';
  disableLogo = false;
  categoryData;
  categorySlug;
  personaUserData;
  slugValue;

  constructor(private http: HttpClient, private storageService: StorageService, private router: Router,
              private deepLinkService: DeepLinkService, private route: ActivatedRoute,
              private uikitService: UikitService, private settingsService: SettingsService,
              private categoryService: CategoriesService,public dialog: MatDialog,
              private homeService: HomeService, private seoService: SeoService) {
      this.callingProgramList = false;
  }

  getBaseUrl(url: string) {
    this.baseURL = url;
    return url;
  }


  fetchToken(userData: any) {
    const userId: string = userData.u_id;
    const devId: string = userData.d_id;
    const guestId: string = userData.g_id;
    let params = new HttpParams()
      .set('d_id', devId);
    if (userId) {
      params = params.append('u_id', userId);
    }
    else {
      params = params.append('g_id', guestId);
    }
    const tokenURL = this.baseURL + token;
    return this.http.get(tokenURL, { params }).subscribe((response: any) => {
      if (response.status_code === 200) {
        const respData = response.data;
        this.storageService.setLocalStore('a_t', respData.access_token);
        this.storageService.setLocalStore('r_t', respData.refresh_token);
        this.router.navigateByUrl('/home');
      }
    }, (error: HttpErrorResponse) => {
      if (error.error.error.code === 1019) {
        this.storageService.removeLocalStore('u_id');
        sessionStorage.removeItem('profileId');
        this.storageService.removeLocalStore('d_id');
        this.storageService.removeLocalStore('g_id');
      }
    });
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  checkDevice() {
    const isMobile = {
      Android() {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry() {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      }
    };
    if (isMobile.iOS()) {
      this.showIosApp = true;
    }
    if (isMobile.Android()) {
      this.showAndroidApp = true;
    }
    if(this.showIosApp || this.showAndroidApp){
      this.browserType = "Mobile Web";
    }else{
      this.browserType = 'Desktop Web';
    }
  }

  checkBrowser(){
    const userAgentString = navigator.userAgent;
    if(userAgentString.indexOf('Chrome') > -1) return "dash";
    if(userAgentString.indexOf('Safari') > -1 && userAgentString.indexOf('Chrome') == -1) return "hls";
    if(userAgentString.indexOf('Chrome') > -1 && userAgentString.indexOf('Safari') > -1) return "dash";
    if(userAgentString.indexOf("Edge") > -1 && userAgentString.indexOf('Edg') > -1) return "dash";
    if(userAgentString.indexOf("Opera") > -1) return "dash";
    if(userAgentString.indexOf("Firefox") > -1) return "dash";
    if(userAgentString.indexOf("MSIE") > -1) return "dash";
  }

  mobileNavigate() {
    if (this.showIosApp) {
      this.deepLinkService.deeplink('isIphone');
    } else if (this.showAndroidApp) {
      this.deepLinkService.deeplink('isAndroid');
    } else {
      const user_id = this.storageService.getLocalStore('u_id');
      if(user_id) {
        this.router.navigateByUrl('/home');
      } else {
        let utm_url = this.settingsService.getUTMurl();
        window.location.href = utm_url;
      }
    }
  }

  signinCounterErrorFn() {
    let signinCounterError = this.storageService.getLocalStore('authErrorCounter');
    if (signinCounterError) {
      signinCounterError = Number(signinCounterError) + 1;
      this.signinCaptchaValidation.next(signinCounterError);
      this.storageService.setLocalStore('authErrorCounter', signinCounterError);
    } else {
      this.signinCaptchaValidation.next(1);
      this.storageService.setLocalStore('authErrorCounter', '1');
    }
  }

  voucherCounterErrorFn() {
    let voucherCounterError = this.storageService.getLocalStore('voucherErrorCounter');
    if (voucherCounterError) {
      voucherCounterError = Number(voucherCounterError) + 1;
      this.voucherCaptchaValidation.next(voucherCounterError);
      this.storageService.setLocalStore('voucherErrorCounter', voucherCounterError);
    } else {
      this.voucherCaptchaValidation.next(1);
      this.storageService.setLocalStore('voucherErrorCounter', '1');
    }
  }

  routeFn(routeValue, key:string){
    let queryParams = this.route.snapshot.queryParams;
    const socailAppTvFlag = this.storageService.getLocalStore(SOCIAL_APP_TV_FLAG);
    const socailSmartTvFlag = this.storageService.getLocalStore(SOCIAL_SMART_APP_TV_FLAG);
    if(queryParams.app === key || socailAppTvFlag === '1'){
      if(socailAppTvFlag === '1'){
        queryParams = { 'app' : this.keyQueryParamTV };
      }
      this.router.navigate([routeValue], {queryParams: queryParams})
    } else if(queryParams.app === this.keyQueryParamAppleTV || socailSmartTvFlag === '1') {
      queryParams = { 'app' : this.keyQueryParamAppleTV };
      this.router.navigate([routeValue], {queryParams: queryParams})
    } else if(queryParams.step === '3' || queryParams.step === '4') {
      this.router.navigate([], {queryParams:routeValue})
    } else {
      this.router.navigateByUrl(routeValue);
    }
  }

  getScrollHeightValue() {
    const doc = document.documentElement;
    const scrollHeightValue = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
    return scrollHeightValue;
  }

  isNumberInput(evt) {
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    else return true;
  }

  onKeyDown(evt) { ["e", "E"].includes(evt.key) && evt.preventDefault() }

  /*Disable form to avoid multiple toast message*/
  disableFormBtnFn() {
    this.disableFormButton = true;
    setTimeout(()=>{
      this.disableFormButton = false;
    },this.uikitService.timeout)
  }

  goBack() {
    history.back();
  }


  navErrPage(errorResult, errRoute) {
    switch (true) {
      case (errorResult.already_subscribed):
        this.router.navigate([`${errRoute}`, 'susbcribed']);
        break;
      case (errorResult.expired):
        this.router.navigate([`${errRoute}`, 'expired']);
        break;
      case (errorResult.invalid):
        this.router.navigate([`${errRoute}`, 'invalid']);
        break;
      case (errorResult.failed):
          this.router.navigate([`${errRoute}`, 'failed']);
          break;
      default:
        this.router.navigate([`${errRoute}`, 'invalid']);
        break;
    }
  }
  isMobileOrNot() {
    return window.innerWidth <= 768;
  }

  newCategoryRedirection(value) {
    const otherNavigationExtras = {
      queryParams: {
        menu_item: value
      }
    };
    if ((this.settingsService.epgEnabled === 1 || this.settingsService.smartChannelsEnabled === 1)   &&  value == 'live-channel') {
      this.epgWiseLivechannelsListing(otherNavigationExtras);
    } else if(value == 'partners'){
      this.router.navigate(['/list/content_partner'], {
        queryParams: {
          component: 'content_partner_widget',
          component_slug:value
        }
      });
    }else this.router.navigate(['/', value], otherNavigationExtras);
  }

  redirect(value, val?) {
    if (value !== 'live-channel') this.closeLiveChannelPLayer();
    const slugValue = typeof(value) == 'string' ? value : value.slug;
    this.slugValue = slugValue;
    this.setPreviousSlugValue(slugValue);
    let queryUniqueId;
    this.categoryService.getCategoryContents(slugValue, queryUniqueId).subscribe(async(data) => {
      if(data) {
        this.categoryData = await data;
        const uId = this.storageService.getLocalStore('u_id');
        let checkBanner = data.find(item => item.component == 'banner_widget');
        if(data.length == 1  && checkBanner) this.redirectCategory(slugValue);
        if (!uId) {
          this.setPreviousSlugValue(slugValue);
          this.redirectCollection(data, value, val);
        } else {
          const paramData = {category:slugValue};
          this.personaUserData = [];
          this.homeService.userContent(paramData).subscribe(async(personaData)=> {
            this.personaUserData = await personaData;
            const filteredData = await this.homeService.addPersonaData(this.personaUserData, data);
              this.redirectCollection(filteredData, value, val);
          })
        }
      }
    });
  }

  getMenuItem(): string {
    const url = this.router.parseUrl(this.router.url);
    let menuItem;
    menuItem = url.queryParams['menu_item'];

     // this is for content partner widget
     if (url.queryParams['component'] && url.queryParams['component_slug']) {
      menuItem = url.queryParams['component_slug'];
    }
    
    if (!menuItem) {
      menuItem = (this.router.url.substring(this.router.url.indexOf('/', 1))).split('/')[1];
    }
    return menuItem;
  }

  setPreviousSlugValue(slugValue) {
    this.previousSlugValue = this.getMenuItem();
    if (this.previousSlugValue === 'live') this.previousSlugValue = 'live-channel';
    if (this.previousSlugValue === slugValue) this.loaderSource.next(false);
    else this.loaderSource.next(true);
    this.previousSlugValue = slugValue;
  }

  redirectCollection(data, value, val?) {
    const otherNavigationExtras = {
      queryParams: {
        menu_item: value
      }
    };
    const collectionLength = data?.length;
    if(collectionLength === 1) {
      const singleCollection = data[0];
      if ((this.settingsService.epgEnabled === 1 || this.settingsService.smartChannelsEnabled === 1)   && singleCollection?.component === "livechannel_widget") {
        this.epgWiseLivechannelsListing(otherNavigationExtras);
        return;
      } else if (singleCollection.component === 'group_collection_widget') this.router.navigate(['/movies/', singleCollection.slug], otherNavigationExtras);
      else if (singleCollection.component === 'banner_widget') this.redirectCategory(this.slugValue);
      else if (singleCollection.component === 'content_partner_widget') this.router.navigate(['/list/',singleCollection.slug], {
        queryParams: {
          component: 'content_partner_widget',
          component_slug:value
        }
      });
      else if (singleCollection?.component === 'my_list_widget') this.router.navigate(['/wishlist'], otherNavigationExtras);
      else if (singleCollection?.component === 'livechannel_widget') this.router.navigate(['/live/',singleCollection.slug], otherNavigationExtras);
      else this.router.navigate(['/collection/',singleCollection.slug], otherNavigationExtras);
    } else this.redirectCategory(value, val);
  }

  redirectCategory(value, val?) {
    this.loaderSource.next(false);
    if(typeof(value) == 'string'){
      this.categorySlug = value;
      const otherNavigationExtras = {
        queryParams: {
          menu_item: value
        }
      };
      this.router.navigate(['/', value], otherNavigationExtras);
    } else {
      this.categorySlug = value.slug;
      const otherNavigationExtras = {
        queryParams: {
          menu_item: value.slug
        }
      };
      this.storageService.setLocalStore(L_CATEGORY_META_DATA, value);
      this.router.navigate(['/', value.slug], otherNavigationExtras);
    }
  }

  epgWiseLivechannelsListing(otherNavigationExtras) {
    const params: HttpParams = new HttpParams()
    .set('skip', "0")
    .set('take', "12")
    .set('version','v1');
    const chaneelURL = environment.apiEndpoint + environment.version + liveChannel;
    this.http.get(chaneelURL, { params }).subscribe((response: any) => {
        if (response.status_code === 200) {
          if(response.data?.livechannels.length > 0) {
            let live_channel_slug = '';
            if(this.storageService.getLocalStore('epg_live_channel')) live_channel_slug = this.storageService.getLocalStore('epg_live_channel');
            else live_channel_slug = response.data?.livechannels[0]?.slug;
            this.router.navigate(['/live/', live_channel_slug], otherNavigationExtras);
          }
        } else {
          this.loaderSource.next(false);
        }
      }, (error: HttpErrorResponse) => {
        this.loaderSource.next(false);
      });
  }

  closeLiveChannelPLayer() {
    const oldPlayer = document.getElementById(this.livePlayerId);
    if (oldPlayer) {
      if (document['pictureInPictureElement']) {
        document['exitPictureInPicture']();
      }
      videojs(oldPlayer).dispose();
      videojs(oldPlayer).hotkeys({
        alwaysCaptureHotkeys: false
      });
    }
    this.settingsService.showHeader = true;
    this.settingsService.showFooter = true;
    this.seoService.defaultMetaTag();
  }

  storeEPGValue(slug) {
    const uId = this.storageService.getLocalStore('u_id');
    // FIXME: Why did we do this?
    // if(uId && this.settingsService.epgEnabled) this.storageService.setLocalStore('epg_live_channel', slug);
    this.storageService.setLocalStore('epg_live_channel', slug);
  }

}
