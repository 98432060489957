import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoggerService } from '../authentication/logger.service';
import { environment } from 'src/environment';
import { BaseService, SettingsService, StorageService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class UserGuard  {

  memoryStorage;
  guest;

  constructor(private baseService: BaseService, private router: Router, private loggerService: LoggerService,
              private storageService: StorageService, private settingsService: SettingsService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let guest;
    this.baseService.guest.subscribe(data => guest = data);
    const uId = this.storageService.getLocalStore('u_id');
    // as per prjct_081 changes: plans page should be displayed before signup. So, even if there is no userid we need to access plans api
    if ((!guest && uId) || (guest && (state?.url.match('/auth/plan') || state?.url.match('/voucher/plan')))) {
      return true;
    } else {
      this.loggerService.updatedMemoryStorage(undefined);
      this.storageService.setLocalStore('logged_in', '0');
      this.storageService.removeLocalStore('u_id');
      sessionStorage.removeItem('profileId');
      this.storageService.removeLocalStore('sessionStorage');
      this.storageService.removeLocalStore('event_user_status');
      this.storageService.removeLocalStore('a_t');
      this.storageService.removeLocalStore('r_t');
      document.cookie = 'u_id=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      this.storageService.removeLocalStore('contentURL');
      if (this.baseService.loginMode === 1) {
        //this.router.navigateByUrl('/auth/signup');
        //return false;
      } else {
        this.baseService.guestSource.next(true);
        //this.router.navigateByUrl('/home');
        //return false;
      }
      let utm_url = this.settingsService.getUTMurl();
      window.location.href = utm_url;
      return false;
    }
  }

}
