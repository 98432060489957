import { Component, OnInit, Input, HostListener, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, ElementRef } from '@angular/core';
import { BaseService, SettingsService, StorageService } from 'src/app/core/services';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { DatalayerService } from 'src/app/core/services/datalayer.service';
import { ContentService } from 'src/app/core/services/content.service';
import { HomeService } from 'src/app/core/services/home.service';
import { WishlistService } from 'src/app/core/services/wishlist.service';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import { PlayContentService } from 'src/app/core/services/play-content.service';
import { environment } from 'src/environment';
import { ApplicationService } from 'src/app/core/services/application.service';
import { AsyncPipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { DynamicSvgColorComponent } from '../dynamic-svg-color/dynamic-svg-color.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MovieDurationPipe } from '../../pipes/movie-duration.pipe';
@Component({
  selector: 'app-slick-item',
  templateUrl: './slick-item.component.html',
  styleUrls: ['./slick-item.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, NgClass, NgStyle, AsyncPipe, DynamicSvgColorComponent, MatProgressBarModule, SlickCarouselModule, MovieDurationPipe]
})
export class SlickItemComponent implements OnInit, OnChanges, OnDestroy {
  imagePath;
  cardConfig;
  cardConfigOrientationTwo;
  SlidesToShowOrientationTwo;
  seriesConfig;
  liveConfig;
  slideToShow;
  slideToScroll;
  slideToShowSeries;
  slideToScrollSeries;
  value;
  windowWidth;
  barwidth;
  searchKey;
  searchActive: boolean;
  datas: any[];
  dataList;
  @Input() data;
  @Input() dataIndex;
  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() categoryName;
  @Input() searchData;
  @Input() pageDetail;
  @Input() noViewMore;
  @Input() contentPartner;
  orientation;
  tags;
  tagsLength: number = 0;
  key;
  guest;
  enableRouting = true;
  liveSlidesToShow: number;
  liveSlideToScroll: number;
  takeValue: number;
  dataCount: any;
  groupCollectionSlidesToShow: number;
  groupCollectionSlidesToScroll: number;
  groupCollectionConfig;
  topInRegionConfig;
  topInRegionSlidesToShow: number;
  topInRegionSlidesToScroll: number;
  buttonClicked = false;
  cardHovered = false;
  preventClick = false;
  genreCurationEnabled;
  restrictedUser;
  partnerUniqueId;
  partnerSlug;
  skinName = environment.skinName;
  cardTriggeringTimeout;
  triggerDatamartForHoverCardSubscribe;
  viewAllLast = false;
  onHoverWishlistSubscriber;

  constructor(public settingsService: SettingsService, private router: Router, private storageService: StorageService,
    private datalayerService: DatalayerService, public contentService: ContentService,
    private homeService: HomeService, private wishListService: WishlistService,
    public configService: ConfigurationService, public baseService: BaseService,private elementRef: ElementRef,
    public playContentService: PlayContentService, public applicationService: ApplicationService, private route: ActivatedRoute) {
    this.takeValue = this.settingsService.takeValue;
    this.imagePath = this.settingsService.imagePath;
    this.genreCurationEnabled = this.settingsService.genreCurationEnabled;
    this.restrictedUser = environment.isEzProxy || (this.storageService.getLocalStore('hoopla_user') == '1') ? true : false;
    this.getAdjustCards();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if (this.data) {
          this.dataCount = this.data.count;
        }
        if (this.data?.list) {
          this.dataList = this.data.list;
        } else if (this.data?.contents) {
          this.dataList = this.data.contents;
        } else if (this.data?.videos) {
          this.dataList = this.data.videos;
        }
        this.dataList = this.dataList.slice(0, (this.data.take_value || this.takeValue) + 1);
        this.key = this.data.component;
        this.orientation = this.data.orientation;
        this.dataList.map(data => {
          if (this.settingsService.enableModerators) data.partnerLogo = this.partnerLogoFilter(data.content_partner_id) || '';
          if (data.tags && typeof (data.tags) == 'string') {
            this.tagsLength = data.tags.length;
            return data.tags = data.tags.split(',');
          } else {
            return;
          }
        });
      }
    }
  }

  partnerLogoFilter(contentId) {
    let matchingId = this.applicationService.contentPartners.find(partner => partner.id == contentId);
    if (matchingId) return matchingId.partner_logo;
  }

  async ngOnInit() {
    this.partnerSlug = this.route.snapshot.paramMap.get('partnerName');
    if (this.partnerSlug) {
      let findPartner = this.applicationService.contentPartners.find(obj => obj.slug == this.partnerSlug);
      this.partnerUniqueId = findPartner.unique_id;
    }
    this.baseService.guest.subscribe(data => this.guest = data);

    if (this.key != 'livechannel_widget' && this.key != 'group_collection_widget' && this.key !== 'top_in_region_widget' && this.key !== 'content_partner_widget') {
      this.triggerDatamartForHoverCardSubscribe = this.homeService.triggerDatamartForHoverCard.subscribe(data => {
        if (data) {
          this.addGTMData(this.homeService.triggerDatamartForHoverCardValue);
          this.homeService.triggerDatamartForHoverCard.next(false);
        }
      })
    }
    this.updateDatawithWishlistInfo(); // this is required for top in region widget wishlist add
    // await this.updateDataWithTvod();
    if(this.data?.key === 'purchases') await this.updateDataWithTvod();
    setTimeout(() => window.dispatchEvent(new Event('resize')), 50);

  }

  updateDataWithTvod() {
    if (this.dataList?.length) {
      this.dataList = this.dataList.map(item => {
        let obj;
        // if (item?.is_tvod && this.data.key !== 'purchases') item.tvodText = this.configService.localeKeys?.['plans.available_to_purchase.tag'] || 'Available to purchase';
        // if (this.data?.key === 'purchases') {
          const tvodObj = this.getTvodInfoforPurchaseWidget(item);
          obj = {...item, ...tvodObj};
        // }
        // we are hiding expire details in all the cards except the purchases widget
        //  else {
        //   const tvodObj = this.contentService.getTvodInfo(item);
        //   obj = {...item, ...tvodObj};
        // }
        return obj;
      });
    }
  }

  getTvodInfoforPurchaseWidget(cardData) {
    let updatedTvodData: any = {};
    if (cardData?.buy && Object.keys(cardData.buy)?.length) {
      updatedTvodData.isBought = true;
      updatedTvodData.tvodText = cardData?.buy.expires_in;
    } else if (cardData?.rent && Object.keys(cardData.rent)?.length)  {
      updatedTvodData.isRented = true;
      updatedTvodData.tvodText = cardData.rent.expires_in;
    }
    return updatedTvodData;
  }

  updateDatawithWishlistInfo() {
    this.homeService.contentWishlist.subscribe((data) => {
      if (this.dataList?.length && data?.length) {
        this.dataList.forEach(item => {
          if (item.is_series) {
            item.wishlist = data.includes(item.series_slug);
          } else {
            item.wishlist = data.includes(item.content_slug);
          }
        });
      } else {
        this.dataList.forEach(banner => {
          banner.wishlist = false;
        });
      }
      this.baseService.loaderSource.next(false);
    })
  }

  onhoverUpdateItemWithWishlistInfo(item){
    this.onHoverWishlistSubscriber = this.homeService.contentWishlist.subscribe(data =>{
      if(data?.length){
        if(item.is_series){
          item.wishlist = data.includes(item.series_slug);
        }else{
          item.wishlist = data.includes(item.content_slug);
        }
      }else{
        item.wishlist = false;
      }
    })
    return item;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    this.getAdjustCards();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
    // stop triggering the absolute card (suppose when hover happen when srolling on the page)
    if (this.cardTriggeringTimeout) clearTimeout(this.cardTriggeringTimeout)
  }

  slickInit(event) {
    const nextArrow = event?.slick?.$nextArrow;
    const prevArrow = event?.slick?.$prevArrow;
    prevArrow.attr('id', 'prev' + this.data.key);
    nextArrow.attr('id', 'next' + this.data.key);
    const prevButton = document.getElementById('prev' + this.data.key)
    prevButton.addEventListener('click', () => this.restrictHoverOnScroll(nextButton))
    const nextButton = document.getElementById('next' + this.data.key);
    nextButton.addEventListener('click', () => this.restrictHoverOnScroll(nextButton))
  }

  restrictHoverOnScroll(btnElem) {
    //restricting the absolute card triggering (suppose mouseover on the card  did when scrolling the carousel on both right and left)
    //start
    if (this.cardTriggeringTimeout) clearTimeout(this.cardTriggeringTimeout);
    //end
    const slickList = btnElem.previousElementSibling;
    const slickElements = slickList.querySelectorAll('.slick-slide');
    if (this.key != 'livechannel_widget' && this.key != 'group_collection_widget' && this.key !== 'top_in_region_widget') {
      this.homeService.hideHoverCardImmediately.next(true)
      slickElements.forEach((elem) => {
        elem.classList.add('no-pointer');
        setTimeout(() => elem.classList.remove('no-pointer'), 300);
      })
    } else {
      slickElements.forEach((elem) => {
        elem.classList.add('no-pointer');
        setTimeout(() => elem.classList.remove('no-pointer'), 100);
      })
    }
  }

  playVideo(value, status) {
    if (status == 'play') {
      this.contentService.play = true;
    } else {
      this.contentService.start = true;
    }
    this.addGTMData(value);
    if (value.is_livechannel) {
      this.baseService.storeEPGValue(value.slug);
    } else if (value.is_series) {
      const seriesSlug = value?.seasons_slug || value?.series_slug;
      const contentSlug = value?.content_slug;
      this.router.navigate(['/series', seriesSlug, contentSlug]);
    } else {
      this.router.navigate(['/video', value.collection_slug, value.content_slug]);
    }
  }

  playTrailer(value) {
    this.contentService.trailer = value;
    this.addGTMData(value);
    if (value.is_series) {
      const seriesSlug = value?.seasons_slug || value?.series_slug;
      const contentSlug = value?.content_slug;
      this.router.navigate(['/series', seriesSlug, contentSlug]);
    } else {
      this.router.navigate(['/video', value.collection_slug, value.content_slug]);
    }
  }

  getAdjustCards() {
    this.windowWidth = window.innerWidth;
    let paddingLeft = 130;
    if (this.windowWidth >= 992 && this.windowWidth <= 1199) {
      paddingLeft = 90;
    } else if (this.windowWidth >= 360 && this.windowWidth < 992) {
      paddingLeft = 16;
    }
    if (this.windowWidth >= 360 && this.windowWidth <= 575) {
      this.slideToShow = ((this.windowWidth - paddingLeft) / (260 + 20));
      this.slideToScroll = this.slideToShow;
      this.SlidesToShowOrientationTwo = ((this.windowWidth - paddingLeft) / (165 + 20));
      this.slideToShowSeries = ((this.windowWidth - paddingLeft) / (125 + 20));
      this.slideToScrollSeries = this.slideToShowSeries;
      this.liveSlidesToShow = ((this.windowWidth - paddingLeft) / (250 + 20));
      this.liveSlideToScroll = this.liveSlidesToShow;
      this.groupCollectionSlidesToShow = ((this.windowWidth - paddingLeft) / (120 + 20));
      this.groupCollectionSlidesToScroll = this.groupCollectionSlidesToShow;
      this.topInRegionSlidesToShow = (this.windowWidth - paddingLeft) / (250 + 20);
      this.topInRegionSlidesToShow = (this.topInRegionSlidesToShow < 1) ? 1 : this.topInRegionSlidesToShow;
      this.topInRegionSlidesToScroll = this.topInRegionSlidesToShow;
      this.barwidth = 0.85;
    } else {
      this.slideToShow = ((this.windowWidth - paddingLeft) / (283 + 20));
      this.slideToScroll = this.slideToShow;
      this.SlidesToShowOrientationTwo = ((this.windowWidth - paddingLeft) / (195 + 20));
      this.slideToShowSeries = ((this.windowWidth - paddingLeft) / (283 + 20));
      this.slideToScrollSeries = this.slideToShowSeries;
      this.liveSlidesToShow = ((this.windowWidth - paddingLeft) / (587 + 20));
      this.liveSlideToScroll = this.liveSlidesToShow;
      this.groupCollectionSlidesToShow = ((this.windowWidth - paddingLeft) / (204 + 20));
      this.groupCollectionSlidesToScroll = this.groupCollectionSlidesToShow;
      this.topInRegionSlidesToShow = (this.windowWidth - paddingLeft) / (580 + 20);
      this.topInRegionSlidesToShow = (this.topInRegionSlidesToShow < 1) ? 1 : this.topInRegionSlidesToShow;
      this.topInRegionSlidesToScroll = this.topInRegionSlidesToShow;
      this.barwidth = 0.85;
    }
    this.cardConfig = {
      centerMode: false,
      infinite: false,
      slidesToShow: (this.slideToShow),
      variableWidth: false,
      slidesToScroll: Math.floor(this.slideToShow)
    };
    this.cardConfigOrientationTwo = {
      centerMode: false,
      infinite: false,
      slidesToShow: (this.SlidesToShowOrientationTwo),
      variableWidth: false,
      slidesToScroll: Math.floor(this.SlidesToShowOrientationTwo)
    };
    this.seriesConfig = {
      centerMode: false,
      infinite: false,
      slidesToShow: (this.slideToShowSeries),
      variableWidth: false,
      slidesToScroll: Math.floor(this.slideToScrollSeries)
    };
    this.liveConfig = {
      centerMode: false,
      infinite: false,
      slidesToShow: this.liveSlidesToShow,
      variableWidth: false,
      slidesToScroll: Math.floor(this.liveSlideToScroll)
    }
    this.groupCollectionConfig = {
      centerMode: false,
      infinite: false,
      slidesToShow: this.groupCollectionSlidesToShow,
      variableWidth: false,
      slidesToScroll: Math.floor(this.groupCollectionSlidesToScroll)
    };
    this.topInRegionConfig = {
      centerMode: false,
      infinite: false,
      slidesToShow: this.topInRegionSlidesToShow,
      variableWidth: false,
      slidesToScroll: Math.floor(this.topInRegionSlidesToScroll)
    }
  }

  addGTMData(video) {
    if (this.categoryName) {
      const datalayerData = {
        event: 'GAEvent',
        eventCategory: 'Content Click',
        eventAction: this.categoryName,
        eventLabel: video.title,
        loginStatus: (this.storageService.getLocalStore('u_id') ? 'True' : 'False'),
        userId: (this.storageService.getLocalStore('u_id') ? this.storageService.getLocalStore('u_id') : ''),
        content: this.datalayerService.getCategoryDatalayer(video, 'content'),
        contentId: video.contentId,
        VideoCategory: this.datalayerService.getCategoryDatalayer(video, 'category'),
        notificationStatus: localStorage.getItem('emailNotify') || 'False',
        region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
        platform: this.baseService.browserType,
        userType: this.storageService.getLocalStore('user_type'),
        operator: this.storageService.getLocalStore('operator_name')
      };
      this.datalayerService.addDatalayer(datalayerData);
    }
    else if (this.searchData) {
      const datalayerData = {
        event: 'GAEvent',
        eventCategory: 'Search',
        eventAction: this.searchData,
        eventLabel: this.settingsService.virtualUrl,
        loginStatus: (this.storageService.getLocalStore('u_id') ? 'True' : 'False'),
        userId: (this.storageService.getLocalStore('u_id') ? this.storageService.getLocalStore('u_id') : ''),
        content: this.datalayerService.getCategoryDatalayer(video, 'content'),
        contentId: video.contentId,
        VideoCategory: this.datalayerService.getCategoryDatalayer(video, 'category'),
        notificationStatus: localStorage.getItem('emailNotify') || 'False',
        region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
        platform: this.baseService.browserType,
        userType: this.storageService.getLocalStore('user_type'),
        operator: this.storageService.getLocalStore('operator_name')
      };
      this.datalayerService.addDatalayer(datalayerData);
    }
    else {
      const datalayerData = {
        event: 'GAEvent',
        eventCategory: 'Content Click',
        eventAction: (video.collection_title) ? video.collection_title : video.seasons_title,
        eventLabel: video.title,
        contentId: video.contentId,
        loginStatus: (this.storageService.getLocalStore('u_id') ? 'True' : 'False'),
        userId: (this.storageService.getLocalStore('u_id') ? this.storageService.getLocalStore('u_id') : ''),
        notificationStatus: localStorage.getItem('emailNotify') || 'False',
        region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
        content: this.datalayerService.getCategoryDatalayer(video, 'content'),
        VideoCategory: this.datalayerService.getCategoryDatalayer(video, 'category'),
        platform: this.baseService.browserType,
        userType: this.storageService.getLocalStore('user_type'),
        operator: this.storageService.getLocalStore('operator_name')
      };
      this.datalayerService.addDatalayer(datalayerData);
    }
  }

  redirect(video, key, title) {
    // to suppress the absolute card trigger after clicking on the card. if any card hovered then hide it back immediately
    //start
    if (this.cardTriggeringTimeout) clearTimeout(this.cardTriggeringTimeout);
    this.homeService.hideHoverCardImmediately.next(true)
    //end

    if (this.enableRouting) {
      this.closeSearch();
      this.addGTMData(video);
      if (video.is_livechannel) {
        this.baseService.storeEPGValue(video.slug);
        if (this.settingsService.epgEnabled) this.contentService.autoPlayVideo = true;
        this.router.navigate(['/live', video.slug]);
      } else if (video.is_series) {
        const seriesSlug = video?.seasons_slug || video?.series_slug;
        const contentSlug = video?.content_slug;
        if (video.watched_duration) {
          this.contentService.contentSlug = contentSlug;
          this.contentService.seriesSlug = seriesSlug;
          this.contentService.seekValue = video.watched_duration;
        } else {
          this.contentService.seekValue = 0;
        }
        this.router.navigate(['/series', seriesSlug, contentSlug]);
      } else if (key === 'video' && this.pageDetail === 'live') {
        if (this.settingsService.epgEnabled) this.contentService.autoPlayVideo = true;
        this.router.navigate(['/live/player', 'video', video.content_id]);
      } else {
        this.router.navigate(['/video', video.collection_slug, video.content_slug]);
      }
    }
  }

  redirectToCollection(value, type, widgetData) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        submenu_title: widgetData?.title_translation_key,
        slug: widgetData?.slug
      }
    };
    this.router.navigate([`/${type}/${value.slug}`], navigationExtras);
  }

  closeSearch() {
    this.closeEvent.emit(true);
  }

  removeContinue(continueId: string) {
    this.preventClick = true;
    this.homeService.removeContinueWatch(continueId).subscribe((data) => {
      if (data) {
        this.preventClick = false;
        this.homeService.updateUserContentFlag = true;
        this.homeService.updateUserContent.next(true);
      }
    });
  }

  removeWishlist(content) {
    if (content.is_series) {
      const paramsData: object = {
        req: { id: content.content_id },
      };
      this.wishListService.removeSeriesWishList(paramsData).subscribe((data) => {
        if (data) {
          this.homeService.updateUserContentFlag = true;
          this.homeService.updateUserContent.next(true);
        }
      });
    } else {
      const paramsData: object = {
        req: { content_type: 'video', id: content.content_id },
      };
      this.wishListService.removeWishlist(paramsData).subscribe((data) => {
        if (data) {
          this.homeService.updateUserContentFlag = true;
          this.homeService.updateUserContent.next(true);
        }
      });
    }
  }

  partnerRouting(value) {
    this.router.navigate(['/content-partner', value.slug]);
  }

  beforeChange($event) {
    this.enableRouting = false;
  }

  afterChange($event) {
    this.enableRouting = true;
  }

  mouseEnter(event, value) {
    // absolute card triggering when hover on the card. and adding hidepointer function to next and back button to hide pointer when scrolling to avoid card trigering in the content detail page
    let target = event.target as HTMLElement
    let rect = target.getBoundingClientRect();
    if(this.windowWidth > 1024){
      if(this.key != 'livechannel_widget' && this.key != 'group_collection_widget' && this.key !== 'top_in_region_widget' && this.key !== 'content_partner_widget'){
        const slickNextButton = this.elementRef.nativeElement.querySelector('.slick-next');
        const slickPrevButton = this.elementRef.nativeElement.querySelector('.slick-prev');
        if (slickNextButton) slickNextButton.addEventListener('click', this.hidePointerScroll.bind(this));
        if(slickPrevButton) slickPrevButton.addEventListener('click', this.hidePointerScroll.bind(this))
        if(this.cardTriggeringTimeout) clearTimeout(this.cardTriggeringTimeout)
        this.triggerHoverCard(rect, value)
      }
    }
    this.hidePrevNextButtonFn(this.dataIndex);
  }

  hidePointerScroll(){
    document.documentElement.style.pointerEvents = 'none';
    setTimeout(()=>{
      document.documentElement.style.pointerEvents = 'auto'; 
    },400)
  }

  mouseLeave() {
    if(this.onHoverWishlistSubscriber) this.onHoverWishlistSubscriber.unsubscribe();
    if(this.key != 'livechannel_widget' && this.key != 'group_collection_widget' && this.key !== 'top_in_region_widget' && this.key != 'content_partner_widget'){
      if(this.cardTriggeringTimeout) clearTimeout(this.cardTriggeringTimeout);
      if(!this.homeService.absoluteCardHovered) this.showPrevNextButtonFn(this.dataIndex)
    } else this.showPrevNextButtonFn(this.dataIndex);
  }

  showPrevNextButtonFn(index){
    let buttonDiv: HTMLElement = document.querySelector('#slick-'+ index);
    if (buttonDiv) {
      let nextButton: HTMLButtonElement = buttonDiv.querySelector('.slick-next');
      let prevButton: HTMLButtonElement = buttonDiv.querySelector('.slick-prev');
      if (nextButton) nextButton.style.cssText += 'opacity: 1;';
      if (prevButton) prevButton.style.cssText += 'opacity: 1;';
    }
  }

  hidePrevNextButtonFn(index){
    let buttonDiv: HTMLElement = document.querySelector('#slick-'+ index);
    if (buttonDiv) {
      let nextButton: HTMLButtonElement = buttonDiv.querySelector('.slick-next');
      let prevButton: HTMLButtonElement = buttonDiv.querySelector('.slick-prev');
      if (nextButton) nextButton.style.cssText += 'opacity: 0;';
      if (prevButton) prevButton.style.cssText += 'opacity: 0;';
    }
  }

  triggerHoverCard(rect, value){
   this.cardTriggeringTimeout = setTimeout(()=>{
      value = this.onhoverUpdateItemWithWishlistInfo(value);
      if(this.onHoverWishlistSubscriber) this.onHoverWishlistSubscriber.unsubscribe();
      this.homeService.hideHoverCard.next(false);
      this.homeService.showHoverCard.next({rect, value, orientation:this.orientation, key:this.key, i:this.dataIndex, enableRouting:this.enableRouting,pageDetail:this.pageDetail,contentPartner:this.contentPartner});
    }, 200)
  }


  viewMore(data) {
    if (data.component === 'group_collection_widget') {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          category: false,
        }
      };
      this.router.navigate([`/movies/${data.slug}`], navigationExtras);
    } else if (data.component === 'content_partner_widget') {
      let navigationExtras: NavigationExtras;
      if (this.partnerSlug) {
        navigationExtras = {
          queryParams: {
            component: data.component,
            partner_id: this.partnerUniqueId
          }
        };
      } else {
        navigationExtras = {
          queryParams: {
            component: data.component,
          }
        };
      }
      this.router.navigate([`/list/${data.key}`], navigationExtras);
    } else if (data.component === 'continue_watching_widget') {
      let navigationExtras: NavigationExtras;
      if (this.partnerSlug) {
        navigationExtras = {
          queryParams: {
            category: this.categoryName,
            partner_id: this.partnerUniqueId
          }
        };
      } else {
        navigationExtras = {
          queryParams: {
            category: this.categoryName,
          }
        };
      }
      this.router.navigate([`/list/${data.key}`], navigationExtras);
    } else if ((data.component === 'my_list_widget') || (data.component === 'top_picks_for_you_widget') || (data.component === 'because_you_watched_widget') || (data.component === 'my_purchases_widget')) {
      let navigationExtras: NavigationExtras;
      if (this.partnerSlug) {
        navigationExtras = {
          queryParams: {
            category: this.categoryName,
            partner_id: this.partnerUniqueId
          }
        };
      } else {
        navigationExtras = {
          queryParams: {
            category: this.categoryName,
          }
        };
      }
      if (data.component === 'because_you_watched_widget') this.router.navigate([`because_you_watched/${data.key}`], navigationExtras);
      else this.router.navigate([`/${data.key}`], navigationExtras);
    } else if (data.component === 'livechannel_widget' && data?.list?.length) {
      let navigationExtras: NavigationExtras;
      navigationExtras = {
        queryParams: {
          component: data.component,
        }
      };
      let live_channel_slug = '';
      if (this.storageService.getLocalStore('epg_live_channel')) live_channel_slug = this.storageService.getLocalStore('epg_live_channel');
      else live_channel_slug = data?.list[0].slug;
      this.baseService.storeEPGValue(live_channel_slug);
      if (this.settingsService.epgEnabled) this.contentService.autoPlayVideo = true;
      if (this.settingsService.epgEnabled || this.settingsService.smartChannelsEnabled) this.router.navigate(['/live/', (live_channel_slug)]);
      else this.router.navigate(['/live/channels'], navigationExtras);
    } else {
      let navigationExtras: NavigationExtras;
      if (this.partnerSlug) {
        navigationExtras = {
          queryParams: {
            component: data.component,
            partner_id: this.partnerUniqueId
          }
        };
      } else {
        navigationExtras = {
          queryParams: {
            component: data.component,
          }
        };
      }
      this.router.navigate([`/list/${data.slug}`], navigationExtras);
    }
  }

  hoverIn(event,dataKey, type, contentId = '', lastCount, index){
    let target = event.target as HTMLElement
    let rect = target.getBoundingClientRect();
    let distanceFromRight = window.innerWidth - rect.right;
    if(contentId == 'view-all') this.hidePrevNextButtonFn(this.dataIndex)
    if(contentId == 'view-all' && !this.viewAllLast){
      if (distanceFromRight < (rect.width)/2)this.viewAllLast = true;
    }
    this.cardHovered = true;
    try {
      const elemId = contentId + dataKey + index;
      const cardElem = document.getElementById(elemId);
      index = index + 1;
      const slickList = cardElem?.closest('.slick-list');
      lastCount = slickList?.firstElementChild?.children?.length || lastCount;
      let nextButtonDisabled = '';
      if (slickList) {
        nextButtonDisabled = slickList.nextElementSibling?.getAttribute('aria-disabled');
      }
      let cardNSlide = Math.floor(this.slideToScroll);
      let translateValue;
      let scale;
      if(type == 'group' || type == 'region' ){
        if (this.windowWidth >= 360 && this.windowWidth <= 1024) {
          switch (type) {
            case 'group':
              translateValue = 0;
              scale = { x: 1, y: 1 };
              cardNSlide = Math.floor(this.groupCollectionSlidesToScroll);
              break;
            case 'region':
              translateValue = 0;
              scale = { x: 1, y: 1 };
              cardNSlide = Math.floor(this.topInRegionSlidesToScroll);
              break;
            default:
              translateValue = 0;
              scale = { x: 1, y: 1 };
              cardNSlide = Math.floor(this.slideToScroll);
              break;
          }
        } else{
          translateValue = 85.4;
          scale = { x: 1.603, y: 1.6 };
          switch (type) {
            case 'group':
              translateValue = 22.4;
              scale = { x: 1.27, y: 1.277 };
              cardNSlide = Math.floor(this.groupCollectionSlidesToScroll);
              break;
            case 'region':
              translateValue = 32.4;
              scale = { x: 1.11, y: 1.1 };
              cardNSlide = Math.floor(this.topInRegionSlidesToScroll);
              break;
            default:
              translateValue = 85.4;
              scale = { x: 1.61, y: 1.6 };
              cardNSlide = Math.floor(this.slideToScroll);
              break;
          }
        }
        const isLastCard = ((lastCount >= cardNSlide && index === lastCount) || (nextButtonDisabled === 'false' && (index % cardNSlide) === 0)) ? true : false;
        if (isLastCard && cardElem) {
          cardElem.style.transform = `translate(-${translateValue}px, 0px) scale(${scale.x}, ${scale.y})`;
          return;
        }
        const lastRemainCards = ((lastCount - (index - 1)) === cardNSlide);
        const isFirstCard = ((lastRemainCards && nextButtonDisabled === 'true') || (index === 1) ||
          (((index % cardNSlide) === 1) && (lastCount - (index - 1)) >= cardNSlide && nextButtonDisabled !== 'true')) ? true : false;
        if (isFirstCard && cardElem) {
          cardElem.style.transform = `translate(${translateValue}px, 0px) scale(${scale.x}, ${scale.y})`;
          return;
        }
      }
    
    } catch (error) {
      console.log(error)
    }
  }

  hoverOut(dataKey, contentId = '', index?) {
    if(contentId == 'view-all') this.showPrevNextButtonFn(this.dataIndex)
    this.viewAllLast = false;
    this.cardHovered = false;
    const elemId = contentId + dataKey + index;
    const cardElem = document.getElementById(elemId);
    if (cardElem?.style.transform) cardElem.style.transform = '';
  }

  navigateToLiveChannelDetail(value) {
    this.router.navigate(['/live', value?.slug]);
  }

  ngOnDestroy() {
    if(this.triggerDatamartForHoverCardSubscribe) this.triggerDatamartForHoverCardSubscribe.unsubscribe();
    if(this.onHoverWishlistSubscriber) this.onHoverWishlistSubscriber.unsubscribe();
  }

}
