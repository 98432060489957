
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApplicationService } from './core/services/application.service';
import { CommonModule } from '@angular/common';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalErrorHandler, JwtInterceptor } from './core/interceptors';

import { CoreModule } from './core/core.module';
import { PublicModule } from './modules/public.module';
import { LayoutModule } from '@angular/cdk/layout';


import { LottieModule, LottieCacheModule } from 'ngx-lottie';
import player from 'lottie-web/build/player/lottie_svg';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { MatProgressBarModule } from '@angular/material/progress-bar';

export function playerFactory() {
  return player;
}

export function initApp(appService: ApplicationService) {
  return () => appService.initApp();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    CoreModule,
    PublicModule,
    CommonModule,
    MatProgressBarModule,
    LayoutModule,
    LottieModule.forRoot({
      player: playerFactory
    }),
    LottieCacheModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [ApplicationService],
      multi: true
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { 
      provide: OverlayContainer, 
      useClass: FullscreenOverlayContainer 
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

