import { Inject, Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { platformSettings, userSettings } from 'src/app/shared/constants/url.constants';
import { environment } from 'src/environment';
import { SettingsService } from './settings.service';
import { FooterService } from './footer.service';
import { StorageService } from './storage.service';
import { UikitService } from './uikit.service';
import { BaseService } from './base.service';
import { ConfigurationService } from './configuration.service';
import { LoggerService } from '../authentication/logger.service';
import { LoadService } from './load.service';
import { LanguageService } from './language.service';
import { Router } from '@angular/router';
import { VersionService } from './version.service';
import * as $ from 'jquery';
import { DOCUMENT } from '@angular/common';
import { PlanService } from './plan.service';
import { EventService } from './event.service';
import { CookieService } from 'ngx-cookie';
import { PaymentService } from './payment.service';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { BehaviorSubject } from 'rxjs';
import { SubscriptionService } from './subscription.service';
import  { forkJoin } from 'rxjs'
import { ContentService } from './content.service';

declare global {
  interface Window {
    dataLayer: Array<any>;
    dengage:any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  public closeDeviceEdit = new BehaviorSubject(false);
  paramurl;
  params;
  type;
  region;
  drmConfig;
  displayQualityLevel;
  phoneCodes: any;
  hooplaParams:any = {};
  regionsList;
  userPaymentMethods: any;
  telcoContries;
  userLoginBy;
  userIntegrationType;
  platformSettingsData;
  userSettingsData;
  playerOptionsData: any;
  contentPartners;
  currencyPrefixSuffix;
  profileImages: any;

  constructor(private http: HttpClient, private settingsService: SettingsService,
              private footerService: FooterService, private storageService: StorageService,
              private uikitService: UikitService, private baseService: BaseService,
              private configService: ConfigurationService, private loggerService: LoggerService,
              private loadService: LoadService, private languageService: LanguageService,
              private router: Router, private versionService: VersionService,
              private planService: PlanService,private eventService: EventService,
              @Inject(DOCUMENT) private HTMLdocument: HTMLDocument,
              private cookieService: CookieService, private paymentService : PaymentService,
              private subscriptionService: SubscriptionService
  ) {
    window.dataLayer = window.dataLayer || [];
  }

  getLocalData = () => {
    if(!this.storageService.getLocalStore('u_id')) {
      const usercookieId = this.cookieService.get('u_id');
      if (usercookieId) {
        localStorage.setItem('u_id', usercookieId);
        this.storageService.removeLocalStore('a_t');
        this.storageService.removeLocalStore('r_t');
      }
    } else {
      let cookieuName = 'u_id';
      let cookieuValue = localStorage.getItem('u_id');
      document.cookie = cookieuName + "=" + cookieuValue + ";domain="+environment.cookieDomain+";path=/";
    }
    const guestcookieId = this.cookieService.get('g_id');
    if (guestcookieId) {
      localStorage.setItem('g_id', guestcookieId);
    }
    const devicecookieId = this.cookieService.get('d_id');
    if (devicecookieId) {
      localStorage.setItem('d_id', devicecookieId);
    }
    const hooplaUser = this.cookieService.get('hoopla_user');
    if (hooplaUser) {
      localStorage.setItem('hoopla_user', hooplaUser);
    }
    const universalUser = this.cookieService.get('universal_user');
    if (universalUser) {
      localStorage.setItem('universal_user', universalUser);
    }
    const hooplaTransactionId = this.cookieService.get('hoopla_transaction_id');
    if (hooplaTransactionId) {
      localStorage.setItem('hoopla_transaction_id', hooplaTransactionId);
    }
    const hooplaStartTime = this.cookieService.get('hoopla_start_time');
    if (hooplaStartTime) {
      localStorage.setItem('hoopla_start_time', hooplaStartTime);
    }
    const hooplaEndTime = this.cookieService.get('hoopla_end_time');
    if (hooplaEndTime) {
      localStorage.setItem('hoopla_end_time', hooplaEndTime);
    }
    return {
      userId : this.storageService.getLocalStore('u_id'),
      deviceId : this.storageService.getLocalStore('d_id'),
      guestId : this.storageService.getLocalStore('g_id'),
      localeSetting : this.storageService.getLocalStore('locale'),
    }
  }

  getParams = (logged_in, localeSetting, debugRegion, debugCountryCode, addVersion?) => {
    let params = new HttpParams()
        .set('d_type', environment.deviceType)
        .set('logged_in', logged_in)
        .set('locale', localeSetting);
    if (debugRegion && debugRegion !== '') {
      params = params.append('debug_region', debugRegion);
    }
    if (debugCountryCode && debugCountryCode !== ''){
      params = params.append('debug_country_code', debugCountryCode);
    }
    if (addVersion){
      params = params.append('version', 'v1');
    }
    return params;
  }

  addMatomo = (uId, dId) => {
    if(uId && this.settingsService.enableMatomoAnalytics){
      const bodyScript = "<script>var _paq = window._paq = window._paq || []; window._paq.push(['appendToTrackingUrl', 'new_visit=1']); _paq.push(['setUserId', '" + uId + "']);_paq.push(['setCustomVariable',1,'deviceId', '" + dId + "','visit']); window._paq.push(['trackPageView']); window._paq.push(['appendToTrackingUrl', '']);</script>";
      $('body').append(bodyScript);
    }
  }

  setColours = (settingsData) => {
    document.body.style.setProperty('--bg-color', settingsData.background_color);
    document.body.style.setProperty('--mm-color', settingsData.menu_color);
    document.body.style.setProperty('--card-color', settingsData.card_color);
    document.body.style.setProperty('--button-color', settingsData.button_color);
    document.body.style.setProperty('--footer-color', settingsData.footer_color);
    document.body.style.setProperty('--bg-font-color', settingsData.background_font_color);
    document.body.style.setProperty('--mm-font-color', settingsData.menu_font_color);
    document.body.style.setProperty('--card-font-color', settingsData.card_font_color);
  }

  addCaptcha = (settingsData) => {
    const enterPriceCaptcha = this.HTMLdocument.createElement('script');
    enterPriceCaptcha.src = "https://www.google.com/recaptcha/enterprise.js?render="+settingsData.google_recaptcha_key;
    $('head').append(enterPriceCaptcha);
  }

  addScripts = (settingsData) => {
    $('head').append(settingsData.web_google_analytics_script);
    $('head').append(settingsData.web_header_script);
    $('body').prepend(settingsData.web_body_script);
  }

  addFavicon = (settingsData) => {
    const favIcon = this.HTMLdocument.createElement('link');
    favIcon.rel = 'icon';
    favIcon.type = 'image/png';
    if (settingsData.image_base_path && settingsData.site_icon) {
      favIcon.href = settingsData.image_base_path + settingsData.site_icon;
    } else {
      favIcon.href = 'https://stagingstatic.tv2zcdn.com/fbstagingassets/images/6ac42ec455311e03794e16df07dc40b6bec9c38d.png';
    }
    $('head').append(favIcon);
  }

  handleError = (error) => {
    // error_codes 1011,1012
    this.baseService.loaderSource.next(false);
    if ((error.error.error.code === 1018 || error.error.error.code === 1019)) {
      this.loggerService.updatedMemoryStorage(undefined);
      this.storageService.removeLocalStore('a_t');
      this.storageService.removeLocalStore('r_t');
      this.storageService.setLocalStore('logged_in', '0');
      this.storageService.removeLocalStore('u_id');
      sessionStorage.removeItem('profileId');
      this.storageService.removeLocalStore('sessionStorage');
      this.storageService.removeLocalStore('event_user_status');
      this.storageService.removeLocalStore('g_id');
      this.storageService.removeLocalStore('d_id');
      document.cookie = 'u_id=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      this.storageService.removeLocalStore('contentURL');
      window.location.reload();
      this.uikitService.notifyError(error.error);
    } else {
      this.uikitService.notifyError(error.error);
    }
  }

  getUserSettings:any = async(params, paramurl) => {
    let settingsUrl = environment.apiEndpoint + environment.v3_version + userSettings;
    if (paramurl) {
      settingsUrl = settingsUrl + paramurl;
    }
    return new Promise((resolve, reject) => {
      return this.http.post<any>(settingsUrl, {}, { params: params })
        .subscribe(
          async (response) => {
            if (response.status_code === 200) {
              const respData = response.data;
              const userPhoneCode = this.phoneCodes.find((obj) => obj.country_code?.toLowerCase() === respData?.configuration?.country_code?.toLowerCase());
              this.loggerService.isVoucherSuspended = respData.user?.voucher?.is_voucher_suspended;
              this.loggerService.userTelco = respData?.user?.user_telco;
              this.subscriptionService.userType = respData.user.user_type || 0;
              this.subscriptionService.userDetails = {name: respData.user?.user_name, email: respData.user?.user_email};
              // code to check stripe_payment_method is present and if present storing it in a variable
              if (respData?.user?.stripe_payment_method?.length > 0) {
                this.userPaymentMethods = respData?.user?.stripe_payment_method;
              }
              if (userPhoneCode) this.storageService.setLocalStore('phone_code', userPhoneCode.phone_code);
              else this.storageService.setLocalStore('phone_code', '');
              return resolve(respData);
            }
            else {
              this.uikitService.notifyError(response.error);
              return resolve(response.error);
            }
          },
          (error) => {
            this.handleError(error);
            return resolve(error);
          }
        );
    });
  }

  getUserPhoneCodeObj() {
    const userPhoneCode = this.storageService.getLocalStore('phone_code');
    const userPhoneCodeObj = this.phoneCodes.find((obj) => obj?.phone_code == userPhoneCode) || {};
    return userPhoneCodeObj;
  }

  async doAutologin() {
    const currentURL: string = window.location.href;
    if(currentURL.includes("kb.idm.oclc.org")) {
      var data = {
        "email":"ezproxyaccess@tv2z.com",
        "password":"up6QN8TBLkFc7qzY",
        "login_by":"manual",
        "device_token":"123456",
        "region": this.storageService.getLocalStore('region') || 'int',
        "locale":this.storageService.getLocalStore('locale') || 'en-US',
      };
      Object.assign(data, this.settingsService.handleDataMartAnalyticsParamsData());
      await this.loggerService.loginFnEzproxy(data);
    }
  }

  initApp = async () => {
    this.generateUniqueId();
    let {userId, deviceId, guestId, localeSetting} = this.getLocalData();
    const locationSearch = location.search;
    let debugRegion = '';
    let debugCountryCode = '';
    const checkType = /type=([a-z.]*)/;
    const checkRegion = /region=([a-z.]*)/;
    const checkDevice = /device_type=([a-z.]*)/;
    const checkCountryCode = /country_code=([a-z.]*)/;
    this.baseService.initLocationHref = location.href;
    if (locationSearch.match(checkType)) {
      if (locationSearch.match(checkType)[1] !== undefined && locationSearch.match(checkType)[1] === 'debug') {
        debugRegion = locationSearch.match(checkRegion) ? locationSearch.match(checkRegion)[1] : null;
        debugCountryCode = locationSearch.match(checkCountryCode) ? locationSearch.match(checkCountryCode)[1] : null;
        const deviceType = locationSearch.match(checkDevice) ? locationSearch.match(checkDevice)[1] : null;
        this.storageService.setLocalStore('debug_region', debugRegion);
        this.storageService.setLocalStore('debug_country_code', debugCountryCode);
        if (deviceType === 'iOS') {
          window.location.href = `tv2z://region=${debugRegion}&type=debug`;
        }
      }
    }
    debugRegion = this.storageService.getLocalStore('debug_region') ?? debugRegion;
    debugCountryCode = this.storageService.getLocalStore('debug_country_code') ?? debugCountryCode;
    localeSetting = localeSetting ?? this.configService.defaultLanguage;
    const locale = this.cookieService.get('locale');
    if (locale) {
      localeSetting = locale;
      this.storageService.setLocalStore('locale', locale);
    }
    let logged_in = 0;
    if (deviceId) {
      if (userId) {
        this.paramurl = '/' + deviceId + '/' + userId;
        logged_in = 1;
      } else if (guestId) {
        this.paramurl = '/' + deviceId + '/' + guestId;
      } else {
        this.storageService.removeAll();
        window.location.reload();
      }
    } else {
      this.paramurl = '';
    }
    
    if(this.storageService.getLocalStore('universal_user') == 'hoopla') {
      let hooplaEndTime = Date.parse(this.storageService.getLocalStore('hoopla_end_time'));
      let currentDateObj = new Date(); // today's date and time in ISO format
      let currentDateTime = Date.parse(currentDateObj.toISOString());
      const error = {"error": {"error" : {"message" : "TransactionId has been Expired"}}};
      if (currentDateTime > hooplaEndTime) {
        this.uikitService.notifyError(new HttpErrorResponse(error));
        this.loggerService.removeLogoutData();
        this.storageService.removeLocalStore('g_id');
        this.storageService.removeLocalStore('d_id');
        window.location.reload();
      }
    }
    this.params = this.getParams(logged_in, localeSetting, debugRegion, debugCountryCode);
    const addVersion = true;
    const platformSettingsParams = this.getParams(logged_in, localeSetting, debugRegion, debugCountryCode, addVersion);
    // this.baseService.loaderSource.next(true);

    const platFormSettingsUrl = environment.apiEndpoint + environment.v3_version + platformSettings;
    let userSettingsUrl = environment.apiEndpoint + environment.v3_version + userSettings;
    if (this.paramurl) {
      userSettingsUrl = userSettingsUrl + this.paramurl;
    }
    const platformSettingsApi = this.http.get<any>(platFormSettingsUrl, { params: platformSettingsParams });
    const userSettingsApi = this.http.post<any>(userSettingsUrl, {}, { params: this.params });

    await this.getPlatformAndUserSettings(platformSettingsApi, userSettingsApi);
    this.telcoContries = this.platformSettingsData.telco_countries;
    this.drmConfig = this.platformSettingsData.drm_config;
    this.phoneCodes  = this.platformSettingsData.phone_codes ?? [];
    const settingsData = this.platformSettingsData.settings;
    this.displayQualityLevel = settingsData.player_quality_selection;

    // profile management
    this.loggerService.userProfiles = this.userSettingsData.user.profiles;
    this.profileImages = this.platformSettingsData?.profile_images;
    this.storageService.setLocalStore('profileLength', this.userSettingsData.user.profiles?.length);
    this.loggerService.isMobileVerified = this.userSettingsData?.user?.user_mobile_verified;
    this.loggerService.isEmailVerified = this.userSettingsData?.user?.user_email_verified;

    // profile info is used in account dropdown. This code is to get profile info even when page is reloaded.
    const profileId = this.storageService.getSessionStore('profileId');
    if (profileId && this.loggerService?.userProfiles) {
      const profileData = this.loggerService?.userProfiles.find(item => {
         return item.profile_id === profileId;
      });
      this.loggerService.profileInfo.next(profileData);
    }
    await this.setUserSettingsconfig(this.userSettingsData);

    const pathNameArr = location.pathname.split('/');
    const regionPath = pathNameArr[1];
    const regionAvailable = this.platformSettingsData.configuration.regions;
    if(this.platformSettingsData.settings.geoblocking_for_content) {
      const countryCode = this.userSettingsData.configuration.country_code;
      this.storageService.setLocalStore('country_code', countryCode);
    } else {
      this.storageService.removeLocalStore('country_code')
      this.storageService.removeLocalStore('debug_country_code')
    }
    const region = this.storageService.getLocalStore('region');
    const notAvailable = settingsData.not_available_region;
    if(notAvailable != '' && region != '' && notAvailable.includes(region)){
      this.router.navigateByUrl('/not-available');
    }
    if (locationSearch.match(checkType) && locationSearch.match(checkType)[1] !== undefined && locationSearch.match(checkType)[1] === 'debug') {
      if (debugRegion && debugRegion !== regionPath && regionAvailable.includes(regionPath)) {
        this.storageService.removeLocalStore('locale');
        location.pathname = location.pathname.replace(regionPath, debugRegion);
      }
    }

    await this.getTranslationsAndLanguageOptions(); // calling player language options and translations json and fetch token api in parallel

    if(this.platformSettingsData.settings.app_user_hoopla_login) {
      try {
        const pathLocationArr = location.pathname.split('/');
        let urlPath;
        let hooplatoken;
        if(pathLocationArr.length == 3) {
          urlPath = pathLocationArr[1];
          hooplatoken = pathLocationArr[2];
        } else {
          urlPath = pathLocationArr[2];
          hooplatoken = pathLocationArr[3];
        }

        if(urlPath == 'hoopla' || urlPath == 'snappet') {
          this.hooplaParams.d_id = this.storageService.getLocalStore('d_id');
          this.hooplaParams.g_id = this.storageService.getLocalStore('g_id');
          this.hooplaParams.region = this.storageService.getLocalStore('region') || 'int';
          this.hooplaParams.locale = this.storageService.getLocalStore('locale') || 'en-US';
          this.hooplaParams.universalKey = hooplatoken;
          this.hooplaParams.loginCustomer = urlPath;
          this.hooplaParams.login_by = "manual";
          if (urlPath == 'snappet') {
            const contentURLParms: RegExp = /[?]contentUrl=([^&#]+)/;
            if (locationSearch.match(contentURLParms)) this.hooplaParams.contentUrl = locationSearch.match(contentURLParms) ? locationSearch.match(contentURLParms)[1] : null;
          }
          await this.loggerService.hooplaLogin(this.hooplaParams);
        }
        } catch (ex){
          console.log(ex);
        }
    }
    if(!this.platformSettingsData?.user?.logged_in && environment.isEzProxy) this.doAutologin();
    await this.configService.setBGImage();
    this.baseService.loginMode = this.userSettingsData.login_mode;
    const isGuestSource = (userId) ? false : true;
    this.baseService.startState = (this.platformSettingsData.login_mode === 0) ? '/home' : '/auth/signup';
    this.baseService.guestSource.next(isGuestSource);
    if (settingsData) {
      // Site theme color settings
      // this.setColours(settingsData);
      const uId = this.storageService.getLocalStore('u_id');
      const dId = this.storageService.getLocalStore('d_id');
      if(this.settingsService.enableMatomoAnalytics) this.addMatomo(uId, dId);
      this.addCaptcha(settingsData);
      this.addFavicon(settingsData);
      this.addScripts(settingsData);
    }
  }

  generateUniqueId(){
    const fpPromise = FingerprintJS.load();
    (async () => {
      const fp = await fpPromise;
      const result = await fp.get();
      this.storageService.setLocalStore('uniq_id', result.visitorId);
    });
  }


  setUserSettingsconfig(userSettingsData) {
    return new Promise<void>((resolve) => {
      const userPhoneCode = this.phoneCodes.find((obj) => obj.country_code?.toLowerCase() === userSettingsData?.configuration?.country_code?.toLowerCase());
      this.loggerService.isVoucherSuspended = userSettingsData.user?.voucher?.is_voucher_suspended;
      this.loggerService.userTelco = userSettingsData?.user?.user_telco;
      this.subscriptionService.userType = userSettingsData.user.user_type || 0;
      this.subscriptionService.userDetails = {name: userSettingsData.user?.user_name, email: userSettingsData.user?.user_email};
      // code to check stripe_payment_method is present and if present storing it in a variable
      if (userSettingsData?.user?.stripe_payment_method?.length > 0) {
        this.userPaymentMethods = userSettingsData?.user?.stripe_payment_method;
      }
      if (userPhoneCode) this.storageService.setLocalStore('phone_code', userPhoneCode.phone_code);
      else this.storageService.setLocalStore('phone_code', '');

      let nCountryCode = this.userSettingsData.configuration['country_code'];
      let nRegion = this.userSettingsData.configuration.region['region_code'];
      let oldRegion = this.storageService.getLocalStore('region');
      if(nRegion != oldRegion){
        this.storageService.setLocalStore('country_code', nCountryCode);
        this.storageService.setLocalStore('region', nRegion);
      }
      const user_region = userSettingsData.user?.user_region || userSettingsData.configuration.region?.region_code;
      this.currencyPrefixSuffix = this.getCurrencySuffixPrefix(user_region);
      this.planService.platformSettingsPlan = this.platformSettingsData.plan;
      const plan = this.platformSettingsData.plan[user_region];
      if (this.userSettingsData.user) {
        this.paymentService.paymentMethodLength = this.userSettingsData.user.stripe_payment_method.length;

        if (!this.storageService.getLocalStore('d_id') || !this.storageService.getLocalStore('g_id')) {
          this.storageService.setLocalStore('d_id', this.userSettingsData.user.d_id);
          this.storageService.setLocalStore('g_id', this.userSettingsData.user.g_id);
        }
        // reccuring payment info
        this.loggerService.checkReccuringPaymentInfo(this.userSettingsData?.user);
      }
      this.eventService.eventFun();
      this.planService.setPlanDetails(user_region);
      this.baseService.checkDevice();
      this.loadService.getSettings();
      this.versionService.setVersion(this.platformSettingsData.version);
      this.settingsService.settingFn(this.platformSettingsData.settings);
      this.footerService.pageDetailsFn(this.platformSettingsData.pages[user_region] || []);
      this.languageService.languageFn(this.platformSettingsData.configuration.locales);
      this.baseService.allPages = this.platformSettingsData.pages[user_region];
      resolve();
    });
  }

  getPlatformAndUserSettings(platformSettingsApi, userSettingsApi) {
    return new Promise<void>((resolve) => {
      forkJoin([platformSettingsApi, userSettingsApi]).subscribe(
        ([pSettings, uSettings]: any) => {
          if (pSettings.status_code === 200 ) {
            this.platformSettingsData = pSettings?.data;
          } else {
            this.uikitService.notifyError(pSettings.error);
          }
          if (uSettings.status_code === 200 ) {
            this.userSettingsData = uSettings?.data;
          } else {
            this.uikitService.notifyError(uSettings.error);
          }
          resolve();
        },
        (error) => {
          // Handle errors
          this.baseService.loaderSource.next(false);
          this.handleError(error);
          console.error('Error:', error);
        }
      );
    });
  }

  getCurrencySuffixPrefix(userRegion) {
    const region_obj = this.platformSettingsData.regions?.find(item => { return item.region_code === userRegion});
    const currency = {
      prefix: region_obj.prefix_currency,
      suffix: region_obj.suffix_currency
    }
    return currency;
  }

  async getTranslationsAndLanguageOptions() {
    let configData = this.platformSettingsData?.configuration;
    this.contentPartners = configData.content_partners || [];
    this.settingsService.contentPartners = this.contentPartners
    configData.region = this.userSettingsData.configuration.region;
    const funcationsArr = [this.loadService.getToken(), this.getPlayerOptions()];
    if(configData) funcationsArr.push(this.configService.loacaleFn(configData));
    await Promise.all(funcationsArr);
  }

  getPlayerOptions() {
    var playerOptionUrl = this.settingsService.player_language_options;
    this.http.get(playerOptionUrl).subscribe((response: any) => {
        this.playerOptionsData = response;
      }, (error) => {
        this.uikitService.notifyError(error);
        console.log('error', error);
      });
  }

}